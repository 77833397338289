<div [ngClass]="{'full-size-map': modoFlota}" class="principal">
  <div *ngIf="!modoFlota" class="flex-item">
    <div fxFlex="20">
      <app-select-con-filtro
        [setOpciones]="groupsOptions"
        [deshabilitado]="false"
        [label]="'Todos los grupos'"
        [setOpcion]="selectedGroups"
        (opcionSeleccionada)="setSelect($event, 'group')"
        [todos]="true"

      >
      </app-select-con-filtro>
    </div>

    <!-- Flota -->
    <div fxFlex="20">
      <app-select-con-filtro
        [setOpciones]="fleetsOptions"
        [deshabilitado]="false"
        [label]="'Todas las flotas'"
        [setOpcion]="selectedFleets"
        (opcionSeleccionada)="setSelect($event, 'fleet')"
        [todos]="true"
      >
      </app-select-con-filtro>
    </div>

    <!-- Patente -->
    <div fxFlex="20">
      <app-select-con-filtro
        [setOpciones]="vehiclesOptions"
        [deshabilitado]="false"
        [label]="'Todos los vehiculos'"
        [setOpcion]="vehiclesSelected"
        (opcionSeleccionada)="setSelect($event, 'vehicle')"
        [todos]="true"
      >
      </app-select-con-filtro>
    </div>
  </div>


  <div class="principal2" [ngClass]="{'full-size-map': modoFlota}">
    <div class="mapa-content">
      <mat-card class="example-card">
        <mat-card-content>
          <app-mapa
            *ngIf="!modoFlota"
            [setVehicles]="markersDashboard"
            [setFocus]="focus"
            [zones]="this.zones"
            (selectedVehicle)="this.openPopUp($event)"
            (toggleInfoWindow)="this.reportOpenCloseInfoWindowOnVehicleMarker($event)"
          ></app-mapa>

          <!-- ********************************************************************************* -->
          <!--SWITCH FLOTA COMPLETA-->
          <mat-slide-toggle
            class="modo-flota"
            color="primary"
            [(ngModel)]="modoFlota"
            (click)="toggleSideBar();">
            Modo flota completa
          </mat-slide-toggle>
          <!-- ********************************************************************************* -->

          <app-flota-completa *ngIf="modoFlota">

          </app-flota-completa>

          <app-simbologia-mapa *ngIf="!modoFlota"></app-simbologia-mapa>


        </mat-card-content>
      </mat-card>
    </div>
    <div [ngClass]="{'scroll-table hidden-div': modoFlota, 'scroll-table':!modoFlota }">
      <div class="table-dashboard">
        <table mat-table [dataSource]="this.dataSource" style="width: 100%;" class="flex-column table-home" aria-describedby="Tabla de vehículos">
          <!--  Column  Definitions -->
          <ng-container matColumnDef="patente">
            <th mat-header-cell *matHeaderCellDef class="no-padding"> Patente</th>
            <td mat-cell class="mat-header-cell" *matCellDef="let element">
              {{element.patente}} {{ element.descripcion !== '' ? '-' + element.descripcion : ''}}
            </td>
          </ng-container>

          <ng-container matColumnDef="grupo">
            <th mat-header-cell *matHeaderCellDef> Grupo</th>
            <td mat-cell class="mat-header-cell" *matCellDef="let element"> {{element.nombreGrupo}}
            </td>
          </ng-container>

          <ng-container matColumnDef="conductor">
            <th mat-header-cell *matHeaderCellDef> Conductor</th>
            <td mat-cell class="mat-header-cell" *matCellDef="let element"> {{element.conductor}}
            </td>
          </ng-container>

          <ng-container matColumnDef="flota">
            <th mat-header-cell *matHeaderCellDef> Flota</th>
            <td mat-cell class="mat-header-cell" *matCellDef="let element"> {{element.nombreFlota}}
            </td>
          </ng-container>

          <ng-container matColumnDef="ubicacion">
            <th mat-header-cell *matHeaderCellDef> Ubicaci&oacute;n</th>
            <td mat-cell class="mat-header-cell" *matCellDef="let element">{{element.point.direccion}}</td>
          </ng-container>

          <ng-container matColumnDef="bateria" *ngIf="this.idsClienteConfiguration.includes(this.idCliente)">

              <th  mat-header-cell *matHeaderCellDef> Batería</th>
              <td mat-cell class="mat-header-cell" *matCellDef="let element">
                <!-- Cuando se está cargando -->
                <span class="battery-container"
                      *ngIf="element.oemBatteryChargeStateTel && element?.oemBatteryChargeStateTel === 1 && element?.oemBatteryChargeLevelTel >= 0">
      <img class="battery-image" [src]="getBatteryImageCharging(element?.oemBatteryChargeLevelTel)" alt="">
      <span class="battery-percentage"
            [style.color]="getBatteryTextColor(element?.oemBatteryChargeLevelTel)">{{element?.oemBatteryChargeLevelTel}}
        %</span>
    </span>
                <!-- Cuando no se está cargando -->
                <span class="battery-container"
                      *ngIf="element?.oemBatteryChargeStateTel === 0 && element?.oemBatteryChargeLevelTel >= 0">
      <img class="battery-image" [src]="getBatteryImageNotCharging(element?.oemBatteryChargeLevelTel)" alt="">
      <span class="battery-percentage"
            [style.color]="getBatteryTextColor(element?.oemBatteryChargeLevelTel)">{{element?.oemBatteryChargeLevelTel}}
        %</span>
    </span>
          <!-- Cuando batteryChargeState y batteryChargeLevel sea -1 y tipo combustible sea Electric -->
          <span class="battery-container"
                  *ngIf="element.oemBatteryChargeStateTel < 0 && element.oemBatteryChargeLevelTel < 0 && element.combustible.toLowerCase() == 'electric'">
                  <span class="battery-space"></span>
                  <img class="battery-image" [src]="getBatteryImageNeverCharged()" alt="">
          </span>
              </td>

          </ng-container>

          <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef> Estado</th>
            <td mat-cell class="mat-header-cell text-center" *matCellDef="let element">
                <span class="btn-estados-table" mat-button>
                  <em [ngClass]="['material-icons', 'icon-estados-table', element.clase]">lens</em>
                </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="accion">
            <th mat-header-cell *matHeaderCellDef> Ver</th>
            <td mat-cell class="mat-header-cell" *matCellDef="let element">
              <button mat-icon-button color="primary" aria-label="Ver marcador en el mapa"
                      (click)="this.mostrarInfoWindowYCentrar(element)">
                <mat-icon>search</mat-icon>
              </button>
            </td>
          </ng-container>


          <tr mat-header-row class="mat-header-row" *matHeaderRowDef="this.idsClienteConfiguration.includes(this.idCliente) ? displayedColumnsWithBattery : displayedColumns"></tr>
          <tr mat-row class="mat-row hover-effect pointer" *matRowDef="let row; columns: this.idsClienteConfiguration.includes(this.idCliente) ? displayedColumnsWithBattery: displayedColumns;"
              (click)="this.mostrarInfoWindowYCentrar(row)"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
    <div class="px-4 py-2 text-center w-100" style="font-size: 0.7rem; color: rgba(0, 0, 0, .3);">Dashboard v{{this.appVersion()}} - <strong>Tranciti</strong></div>
  </div>

  <app-panel-indicadores *ngIf="!modoFlota" [ngClass]="{'position': 'relative', 'display': 'block'}"
                         [iFlota]="iFlota"
                         [iAlerta]="iAlerta"
                         [iActividad]="iActividad"
  ></app-panel-indicadores>
</div>

