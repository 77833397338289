import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'app/_services/login.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private readonly router: Router, private readonly loginService: LoginService, private readonly cs: CookieService) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const ox1oo = this.cs.get('0x100');
    const wpsid = this.cs.get('wpsid') || localStorage.getItem('wpsid');
    const php = this.cs.get('PHPSESSID') || localStorage.getItem('PHPSESSID');
    const java = this.cs.get('JSESSIONID') || localStorage.getItem('JSESSIONID');

    const response = this.loginService.recoverSession();

    if (!response || !wpsid || !php || !java || !ox1oo) {
      document.cookie.split(";").forEach(function(c) { 
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
      Object.keys(localStorage).forEach(key => {
        if (!key.startsWith('comp') && !key.startsWith('allFilters')) {
          localStorage.removeItem(key);
        }
      });
      this.router.navigate(['/login']);
    } else {
      console.log("Auther", true);
    }

    return response;

  }

  getCookieOrLocalstorage(name: string) {
    let cookie = this.cs.get(name);

    if ( !cookie || cookie === undefined) {
      cookie = localStorage.getItem(name);
    }

    return cookie;
  }
}

