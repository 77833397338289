import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-select-con-filtro',
  templateUrl: './select-con-filtro.component.html',
  styleUrls: ['./select-con-filtro.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectConFiltroComponent implements OnInit {
  @Input() set setOpciones(opcs) {
    this.opciones = opcs;
    this.filtroOpcion = '';
  }
  @Input() label = '';
  @Input() deshabilitado = false;
  @Input() todos = false;
  @Input() defecto = false;
  @Input() group = false;
  @Input() set setOpcion(opc: number) {
    this.opcion = opc;
    this.cdr.detectChanges();
  }
  @Input() multiple = true;
  @Input() guardarSeleccion = false;

  @Output() opcionSeleccionada = new EventEmitter<number>();

  public filtroOpcion = '';
  public opcion: number;
  public opciones: any[];
  all = true;

  // Creamos un Subject para manejar las emisiones con debounceTime
  private opcionSeleccionadaSubject = new Subject<number>();

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.guardarSeleccion) {
      const id = localStorage.getItem('id');
      const opcionesGuardadas = JSON.parse(localStorage.getItem('comp-opcionesSeleccionadas-' + id));
      if (opcionesGuardadas) {
        this.opcion = opcionesGuardadas;
        this.cdr.detectChanges();
        // No emitimos el evento aquí de inmediato
        this.opcionSeleccionadaSubject.next(this.opcion);  // Enviamos al Subject
      }
    }

    // Aplicamos debounceTime al Subject y luego emitimos el evento real
    this.opcionSeleccionadaSubject.pipe(
      debounceTime(300)  // Espera 300 ms antes de emitir el evento
    ).subscribe(opcion => {
      this.opcionSeleccionada.emit(opcion);  // Emite el evento después del debounce
    });
  }

  filtrarOpciones() {
    return this.opciones.filter((opcion: any) => {
      try {
        return opcion.descripcion.toLowerCase().includes(this.filtroOpcion.toLowerCase());
      } catch (e) {
        return false;
      }
    });
  }

  opcionCambiada() {
    this.opcionSeleccionadaSubject.next(this.opcion);  // En lugar de emitir directamente, enviamos al Subject
    if (this.guardarSeleccion) {
      const id = localStorage.getItem('id');
      localStorage.setItem('comp-opcionesSeleccionadas-' + id, JSON.stringify(this.opcion));
      this.cdr.detectChanges();
    }
  }

  selectAll() {
    if (this.all) {
      this.all = false;
      const a: any = this.opciones.map((i: any) => i.id).map(Number);
      this.opcion = a;
      this.opcionSeleccionadaSubject.next(a);  // Emisión con debounce
    } else {
      this.all = true;
      this.opcionSeleccionadaSubject.next(null);  // Emisión con debounce
    }
  }
}
