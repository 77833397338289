<div class="body-selectores-opciones-flota-completa">
  <span class="subtitle-selector">
    Active o desactive sus preferencias:
  </span>
  <div class="div-preferencias-flota-completa">
    <section class="check-item-flota-completa" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="half-per">
        <mat-slide-toggle color="primary" [checked]="this.mapOptions.automaticFit"
          (change)="doChange($event.checked, 'fit')">
          Ajuste automatico
        </mat-slide-toggle>
      </div>
      <div class="half-per">

        <mat-slide-toggle color="primary" [checked]="this.mapOptions.showLabels"
        (change)="doChange($event.checked, 'labels')" disabled=false>
        Visualizar labels
      </mat-slide-toggle>
      </div>
    </section>
    <section class="check-item-flota-completa" >
      <div class="half-per">
        <mat-slide-toggle color="primary" [checked]="this.mapOptions.showZones"
        (change)="doChange($event.checked, 'zones')" disabled=false>
          Visualizar zonas
        </mat-slide-toggle>
      </div>
      <div class="half-per">
        <mat-slide-toggle color="primary" [checked]="this.mapOptions.showPoints"
        (change)="doChange($event.checked, 'points')" >
          Visualizar puntos
        </mat-slide-toggle>
      </div>
    </section>
    <section class="check-item-flota-completa">
      <div class="half-per">
        <mat-slide-toggle color="primary" [checked]="this.mapOptions.showTraffic"
          (change)="doChange($event.checked, 'traffic')" >
          Visualizar trafico
        </mat-slide-toggle>
      </div>
      <div class="half-per">
        <mat-slide-toggle color="primary" [checked]="this.mapOptions.showCluster"
          (change)="doChange($event.checked, 'cluster')">
          Cluster
        </mat-slide-toggle>
      </div>
    </section>
    <section class="check-item-flota-completa">
      <div class="half-per" *ngIf="this.isClientConfigurate || this.isClientConfigurateOnlyOnRoute">
        <mat-slide-toggle color="primary" [checked]="this.mapOptions.onRoute"
                          (change)="doChange($event.checked, 'onRoute')">
          En Ruta
        </mat-slide-toggle>
      </div>
      <div class="half-per" >
        <mat-slide-toggle color="primary" [checked]="this.mapOptions.showCog"
                          (change)="doChange($event.checked, 'cog')" >
          Visualizar sentido de vehículos
        </mat-slide-toggle>
      </div>
    </section>
    <section >
      <div class="div-espacio-flota-completa">
        <p>&nbsp;</p>
      </div>
    </section>
  </div>

</div>
