export class Vehiculo2 {
  id: number;
  patente: any;
  descripcion: string;
  imei: any;
  estado: string;
  icon: Icon;
  iconDefaultUrl: string;
  point: Point;
  lastPoint: Point;
  color: string;
  clase: string;
  tipo: any;
  idGrupo: number;
  idClase: number;
  nombreGrupo: string;
  nombreFlota: string;
  selected: boolean;
  isOpenInfoWindow: boolean;
  temperatura: TemperaturaInputs;
  conductor: string;
  oemBatteryChargeStateTel: null | number;
  oemBatteryChargeLevelTel: null | number;
  obdEomTotalMileageTel: null | number;
  combustible: string;
  /* PROYEC-816 desde req
   * 1 = Detenido
   * 2 = Encendido
   * 3 = Apagado
   * 4 = Online
   * 5 = Online Telemetria
   * 6 = N/A (hrs)
   * 7 = N/A (dias)
   * 8 = En Taller
   */
  colores = [
    '',
    'amarillo',
    'verde',
    'rojo',
    'azul',
    'azul',
    'gris',
    'gris',
    'negro',
  ];

  clases = [
    '',
    'color-detenido',
    'color-encendido',
    'color-apagado',
    'color-online',
    'color-online',
    'color-off',
    'color-off',
    'color-taller'
  ];

  estados = [
    '',
    'Detenido',
    'Encendido',
    'Apagado',
    'Online',
    'Online',
    'Offline',
    'Offline',
    'En Taller'
  ];


  constructor() {
  }
  
  // Método para clonar la instancia actual
  clone(): Vehiculo2 {
    const clone = new Vehiculo2();

    clone.id = this.id;
    clone.patente = this.patente;
    clone.descripcion = this.descripcion;
    clone.imei = this.imei;
    clone.estado = this.estado;
    clone.icon = this.icon;
    clone.iconDefaultUrl = this.iconDefaultUrl;
    clone.point = this.point ? new Point(this.point) : null;
    clone.lastPoint = this.lastPoint ? new Point(this.lastPoint) : null;
    clone.color = this.color;
    clone.clase = this.clase;
    clone.tipo = this.tipo;
    clone.idGrupo = this.idGrupo;
    clone.idClase = this.idClase;
    clone.nombreGrupo = this.nombreGrupo;
    clone.nombreFlota = this.nombreFlota;
    clone.selected = this.selected;
    clone.isOpenInfoWindow = this.isOpenInfoWindow;
    clone.temperatura = this.temperatura ? new TemperaturaInputs(this.temperatura) : null;
    clone.conductor = this.conductor;
    clone.oemBatteryChargeStateTel = this.oemBatteryChargeStateTel;
    clone.oemBatteryChargeLevelTel = this.oemBatteryChargeLevelTel;
    clone.obdEomTotalMileageTel = this.obdEomTotalMileageTel;
    clone.combustible = this.combustible;

    return clone;
  }

  setData(data: any, selected = true, isOpenInfoWindow = false) {
    this.descripcion = data.descripcion;
    this.id = data.vehicleId ? data.vehicleId : data.vehicleid;
    this.patente = data.patente;
    this.imei = data.imei;
    this.tipo = data.icono;
    this.idGrupo = data.idGrupo;
    this.idClase = data.idClase;
    this.setPoint(data);
    this.selectColor(data);
    this.selected = selected;
    this.isOpenInfoWindow = isOpenInfoWindow;
    this.temperatura = new TemperaturaInputs(data);
    this.conductor = data.conductor;
    this.oemBatteryChargeStateTel =  data.oemBatteryChargeStateTel ;
    this.oemBatteryChargeLevelTel =  data.oemBatteryChargeLevelTel ;
    this.obdEomTotalMileageTel =  data.obdEomTotalMileageTel ;
    this.combustible = data.combustible;
  }

  setPoint(data: any) {
    if (this.point) {
      this.lastPoint = this.point;
    }
    this.point = new Point(data);
  }

  selectColor(data: any) {
    const idestado = data.estado;
    if (idestado > 0 && this.colores[idestado]) { // tiene algún estado definido, comienzan en 1
      this.color = this.colores[idestado];
      this.estado = this.estados[idestado];
      this.clase = this.clases[idestado];
    } else { // x defecto
      this.color = this.colores[6];
      this.estado = this.estados[6];
      this.clase = this.clases[6];
    }
    this.iconDefaultUrl = `./${this.tipo}-${this.color}.svg`;
  }

  rotateImg(url: any, cog: number) {
    const canvas = document.createElement('canvas');
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = url;
    img.onload =  () => {
      const centerX = img.width / 2;
      const centerY = img.height / 2;
      const context = canvas.getContext('2d');
      canvas.width = img.height + 40;
      canvas.height = img.height + 40;
      context.save();
      context.translate(centerX, centerY);
      context.rotate(cog * Math.PI / 180);
      context.drawImage(img, -centerX, -centerY);
      context.restore();
      this.icon = {
        url: canvas.toDataURL('image/png'),
        scaledSize: new google.maps.Size(60, 60)
      };
    };
  }
}



class Point {
  sog: any;
  cog: any;
  lat: any;
  lng: any;
  old: any;
  ignition: any;
  millis: any;
  direccion: string;


  constructor(data: any) {
    this.sog = data.speed;
    this.cog = data.cog;
    this.lat = data.hasOwnProperty("lat") ? data.lat : data.latitud;
    this.lng = data.hasOwnProperty("lon") ? data.lon : data.longitud;
    this.old = data.old;
    this.ignition = data.ignicion;
    this.millis = data.hasOwnProperty("utc") ? data.utc : data.fechaEmision;
    this.direccion = '---';
  }
}

class TemperaturaInputs {
  isTemperatura: boolean;
  adc1: number;
  adc2: number;
  adc3: number;
  adc4: number;
  noT = -200;

  constructor(data: any) {
    this.adc1 = data.adc;
    this.adc2 = data.adc2;
    this.adc3 = data.adc3;
    this.adc4 = data.adc4;


    if (this.validacion(this.adc1) || this.validacion(this.adc2) || this.validacion(this.adc3) || this.validacion(this.adc4)) {
      this.isTemperatura = true;
    } else {
      this.isTemperatura = false;
    }
  }

  validacion(adc) {
    if ( adc !== this.noT && (adc > -200 && adc < 200)) {
      return true;
    } else {
      return false;
    }
  }

}

interface Icon {
  url: string;
  scaledSize: google.maps.Size;
}

