export class Vehiculo {
  id: any;
  patente: any;
  descripcion: string;
  imei: any;
  estado: string;
  icon: Icon;
  iconDefaultUrl: string;
  point: Point;
  lastPoint: Point;
  color: string;
  clase: string;
  tipo: any;
  idGrupo: number;
  idClase: number;
  nombreGrupo: string;
  nombreFlota: string;
  selected: boolean;
  isOpenInfoWindow: boolean;
  temperatura: TemperaturaInputs;
  conductor: string;
  oemBatteryChargeStateTel: null | number;
  oemBatteryChargeLevelTel: null | number;
  obdEomTotalMileageTel: null | number;
  	/* PROYEC-816 desde req
		 * 1 = Detenido
		 * 2 = Encendido
		 * 3 = Apagado
		 * 4 = Online
		 * 5 = Online Telemetria
		 * 6 = N/A (hrs)
		 * 7 = N/A (dias)
		 * 8 = En Taller
		 */
    colores = [
      '',
      'amarillo',
      'verde',
      'rojo',
      'azul',
      'azul',
      'gris',
      'gris',
      'negro',
    ];

    clases = [
      '',
      'color-detenido',
      'color-encendido',
      'color-apagado',
      'color-online',
      'color-online',
      'color-off',
      'color-off',
      'color-taller'
    ];

    estados = [
      '',
      'Detenido',
      'Encendido',
      'Apagado',
      'Online',
      'Online',
      'Offline',
      'Offline',
      'En Taller'
    ];


  constructor() {
  }

  setData(data: any, selected = true, isOpenInfoWindow = false) {
    this.descripcion = data.D1;
    this.id = data.W1;
    this.patente = data.W3;
    this.imei = data.W2;
    this.tipo = data.W6; //icono
    this.idGrupo = data.G1;
    this.idClase = data.W5;
    this.setPoint(data);
    this.selectColor(data);
    this.selected = selected;
    this.isOpenInfoWindow = isOpenInfoWindow;
    this.temperatura = new TemperaturaInputs(data);
    this.conductor = data.D3;
  }

  setPoint(data: any) {
    if (this.point) {
      this.lastPoint = this.point;
    }
    this.point = new Point(data);
  }

  selectColor(data: any) {
    const idestado = data.W101;
    if (idestado > 0 && this.colores[idestado]) { // tiene algún estado definido, comienzan en 1
      this.color = this.colores[idestado];
      this.estado = this.estados[idestado];
      this.clase = this.clases[idestado];
    } else { // x defecto
      this.color = this.colores[6];
      this.estado = this.estados[6];
      this.clase = this.clases[6];
    }
    this.iconDefaultUrl = `./${this.tipo}-${this.color}.svg`;
  }

  rotateImg(url: any, cog: number) {
    const canvas = document.createElement('canvas');
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = url;
    img.onload =  () => {
        const centerX = img.width / 2;
        const centerY = img.height / 2;
        const context = canvas.getContext('2d');
        canvas.width = img.height + 40;
        canvas.height = img.height + 40;
        context.save();
        context.translate(centerX, centerY);
        context.rotate(cog * Math.PI / 180);
        context.drawImage(img, -centerX, -centerY);
        context.restore();
        this.icon = {
          url: canvas.toDataURL('image/png'),
          scaledSize: new google.maps.Size(60, 60)
        };
    };
  }
}



class Point{
  sog: any;
  cog: any;
  lat: any;
  lng: any;
  old: any;
  ignition: any;
  millis: any;
  direccion: string;


  constructor(data: any) {
    this.sog = data.W12;
    this.cog = data.W15;
    this.lat = data.W9;
    this.lng = data.W10;
    this.old = data.W8;
    this.ignition = data.W25;
    this.millis = data.W7;
    this.direccion = '---';
  }
}

class TemperaturaInputs {
  isTemperatura: boolean;
  adc1: number;
  adc2: number;
  adc3: number;
  adc4: number;
  noT = -200;

  constructor(data: any) {
    this.adc1 = data.W17;
    this.adc2 = data.W18;
    this.adc3 = data.W38;
    this.adc4 = data.W39;


    if (this.validacion(this.adc1) || this.validacion(this.adc2) || this.validacion(this.adc3) || this.validacion(this.adc4)) {
      this.isTemperatura = true;
    } else {
      this.isTemperatura = false;
    }
  }

  validacion(adc) {
    if ( adc !== this.noT && (adc > -200 && adc < 200)) {
      return true;
    } else {
      return false;
    }
  }

}

interface Icon {
  url: string;
  scaledSize: google.maps.Size;
}

