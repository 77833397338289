<div class="container relative"  fxLayout="row" fxLayoutAlign="space-between start">
    <div class="container-tipo">
      <h4 class="titulo-historial">Vista últimas 48 horas del móvil</h4>
      <div class="mapa-content">
        <mat-card class="example-card">
          <mat-card-content>
            <app-mapa-historico
              [setPolyline]="routePoints"
              [inicio]="startMarker"
              [setPuntosRuta]="routePoints"
              [setPuntoOrigen]="startMarker"
              [setPuntoDestino]="endMarker"
              >
            </app-mapa-historico>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="mat-table-light" *ngIf="!conDatos">
        <h4 class="sin-datos">Sin datos en las últimas 48 horas</h4>
      </div>
      <div class="mat-table-light" *ngIf="conDatos">
        <div class="mat-row-light" >
          <div class="mat-cell-light"><strong>Fecha Hora inicio:</strong> <span>{{fechaInicio}}</span></div>
          <div class="mat-cell-light width-2-tercios"><strong>Origen </strong>
            <span>
              <i class="material-icons">
                place
              </i>
                :
                {{lugarInicio}}.
            </span>
          </div>
        </div>
        <div class="mat-row-light">
          <div class="mat-cell-light"><strong>Fecha Hora termino:</strong> <span>{{fechaTermino}}</span></div>
          <div class="mat-cell-light width-2-tercios"><strong>Destino </strong>
            <span>
              <i class="material-icons">
                place
              </i>
                :
                {{lugarTermino}}
            </span>
          </div>
        </div>
        <div class="mat-row-light">
          <div class="mat-cell-light"><strong>Tiempo en conducción:</strong> <span>{{ptime}}</span></div>
          <div class="mat-cell-light"><strong>Tiempo detenido:</strong> <span>{{tiempoDetenido}}</span></div>
          <div class="mat-cell-light"><strong>Distancia Recorrida:</strong> <span>{{vdistance}}</span></div>
        </div>
        <div class="mat-row-light">
          <div class="mat-cell-light"><strong>Velocidad Promedio:</strong> <span>{{vvavg}}km/hr</span></div>
          <div class="mat-cell-light"><strong>Velocidad Máx.</strong> <span>{{vvmax}}km/hr</span></div>
        </div>
      </div>

      <button mat-flat-button color="primary" [routerLink]="back" class="volver-btn pull-right margin-top-10">
        <i class="material-icons">
          arrow_back
        </i>
        &nbsp;
        <span class="mat-button-wrapper">Volver a Inicio</span>
        &nbsp;
      </button>

    </div>
  </div>
