<div class="datos-historial">
    <h3>
        Datos de consulta de historial de ruta
    </h3>
    <div class="item-datos">
        <span class="item-datos-1">
            Inicial:
        </span> 
        <span class="item-datos-2">
            {{this.historicData && this.historicData.startMarker.punto.epoch ? (this.historicData.startMarker.punto.epoch * 1000 | date:'dd-MM-yyyy hh:mm') : ''}}
        </span></div>
    <mat-divider></mat-divider>
    <div class="item-datos">
        <span class="item-datos-1">
            Origen:
        </span> 
        <span class="item-datos-2">
            {{this.historicData && this.historicData.lugarInicio ? this.historicData.lugarInicio : ''}}
        </span>
    </div>
    <mat-divider></mat-divider>
    <div class="item-datos">
        <span class="item-datos-1">
            Final:
        </span> 
        <span class="item-datos-2">
            {{this.historicData && this.historicData.endMarker.epoch ? (this.historicData.endMarker.epoch * 1000 | date:'dd-MM-yyyy hh:mm') : ''}}
        </span></div>
    <mat-divider></mat-divider>
    <div class="item-datos">
        <span class="item-datos-1">
            Destino:
        </span> 
        <span class="item-datos-2">
            {{this.historicData && this.historicData.lugarTermino ? this.historicData.lugarTermino : ''}}
        </span>
    </div>
    <mat-divider></mat-divider>
    <div class="item-datos">
        <span class="item-datos-1">
            Distancia:
        </span> 
        <span class="item-datos-2">
            {{this.coveredDistance}}
        </span>
    </div>
    <mat-divider></mat-divider>
    <div class="item-datos">
        <span class="item-datos-1">
            Velocidad:
        </span> 
        <span class="item-datos-2">
            Prom: {{this.averageSpeed}} |
            Max: {{this.maxSpeed}} 
        </span>
    </div>
    <mat-divider></mat-divider>
</div>