export class WPSID {
  name: string;
  value: string;

  constructor(value: string) {
    this.name = 'wpsid';
    this.value = value;
  }

  getUrlEncode() {
    return `${this.name}=${localStorage.getItem('wpsid')}`;
  }
}
