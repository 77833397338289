import { PointsService } from 'app/_services/points/points.service';//dashboard-component
import { ZonesService } from 'app/_services/zones/zones.service';
import { StorageService } from 'app/_services/storage/storage.service';
import { MapOptions } from 'app/_class/map-options';
import { VehiclesService } from 'app/_services/vehicles/vehicles.service';
import { FleetsService } from 'app/_services/fleets/fleets.service';
import { GroupsService } from 'app/_services/groups/groups.service';
import { Component, OnInit, EventEmitter, Output, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Vehiculo } from 'app/_class/vehiculo';//dashboard-component
import * as xml2js from 'xml2js';
import { Subscription, of, forkJoin } from 'rxjs';
import { SocketService } from 'app/_services/vehicles/socketService .service';
import { Vehiculo2 } from 'app/_class/vehiculo2';
import { AlertsByClient } from "../../_interfaces/alerts";
import { AllVehicle } from "../../_interfaces/allVehicle";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-menu-flota-completa',
  templateUrl: './menu-flota-completa.component.html',
  styleUrls: ['./menu-flota-completa.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuFlotaCompletaComponent implements OnInit, OnDestroy {

  filtros = false;
  private readonly strBase = 'comp';
  idClientConfigurate: number[] = [];
  idClientConfigurateOnRoute: number[] = [];
  idUserLoginOpen: number;
  @Input() set reloadPoints(clients: number[]) {
    this.loadPoints(clients);
  }

  @Input()
  public clients = [];


  @Input()
  public vehicles = [];

  private subscriptions: Subscription[] = [];
  public allVehicles = [];
  public allVehiclesAlert: AllVehicle[] = [];
  public listToPrint = [];
  public controls = new Controls();

  public zones = [];
  public points = [];

  public loadingData = false;

  public showMenu = false;

  public filters: Filters;
  public filterEntity: FiltersEntity;
  public selects: Selects;
  public allVehiclesMap = new Map();

  public clientVehiclesMap = new Map();

  public selectOptions = new SelectOptions();

  public filteredVehicles: Vehiculo[] = [];
  public filteredVehiclesAll: Vehiculo[] = [];

  public clientConfigurate = false;
  public clientConfigurateOnRoute = false;
  public vehiclesIdsSelected = {};

  public filtroOpcionesVehiculos = '';

  @Output()
  public emitAlerts  = new EventEmitter();

  @Output()
  private toggleOptions = new EventEmitter();

  @Output()
  private emitVehicles = new EventEmitter();

  @Output()
  private emitZones = new EventEmitter();

  @Output()
  private emitPoints = new EventEmitter();

  @Output()
  private setFocus = new EventEmitter();

  @Output()
  private emitMapOptions = new EventEmitter();

  @Output() emitClients = new EventEmitter();

  private first = true;
  private intervalId: any;
  selectedVehicles = [];

  constructor(
    private groupsService: GroupsService,
    private fleetsService: FleetsService,
    private vehiclesService: VehiclesService,
    private storage: StorageService,
    private zonesService: ZonesService,
    private pointsService: PointsService,
    private socketService: SocketService,
    private http: HttpClient
  ) { }


  async ngOnInit() {
    this.getClientConfigurateWithTxt().then(r => true );
    this.getClientConfigurateOnRouteWithTxt().then(r => true );
    this.selects = new Selects();
    this.filters = new Filters();
    this.filterEntity = new FiltersEntity();
    this.idUserLoginOpen = Number(localStorage.getItem('id'));
    const allUserFilters = JSON.parse(localStorage.getItem('allFilters')) || {};
    if (allUserFilters[this.idUserLoginOpen]) {
      Object.assign(this.selects, allUserFilters[this.idUserLoginOpen].selects);
      Object.assign(this.selectOptions, allUserFilters[this.idUserLoginOpen].selectOptions);
      this.filtros = true;
    }

    // intervalo para que se ejecute cada 10 minutos
    this.intervalId = setInterval(() => {
      this.loadAlerts();
    }, 30000);
    for (const cl of this.clients) {
      this.filterEntity.clients[cl.id] =  cl;
    }
    const clientsSaved: any[] = this.storage.get(this.strBase, 'cls');
    if (!clientsSaved) {
      return;
    }
    this.emitClients.emit(clientsSaved);
    this.controls.clients = [];

    setTimeout(() => {
      this.controls.clients = clientsSaved.map(cl => cl.id);
      this.reloadSelectedOptions('clients');
    }, 1500);



    this.reloadSelectedOptions('clients');
    await this.refreshSelects(this.selects.clientsSelected);
  }
  ngOnDestroy() {
    // Limpia el intervalo cuando el componente se destruye
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.resetAllData();

  }
  resetAllData() {
    this.controls.clients = [];
    this.filtros = false;
    this.clients = [];
    this.vehicles = [];
    this.subscriptions = [];
    this.allVehicles = [];
    this.allVehiclesAlert = [];
    this.listToPrint = [];
    this.controls = new Controls();
    this.zones = [];
    this.points = [];
    this.loadingData = false;
    this.showMenu = false;
    this.filters = new Filters();
    this.filterEntity = new FiltersEntity();
    this.allVehiclesMap = new Map();
    this.clientVehiclesMap = new Map();
    this.filteredVehicles = [];
    this.vehiclesIdsSelected = {};
    this.filtroOpcionesVehiculos = '';
    this.first = true;


    this.toggleMapOptions(new MapOptions());
    this.print([]);
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  async reloadLists() {
    const dataVehicles: any = await this.storage.getFromDB(this.strBase, 'vehiclesToShow');
    const { content } = await this.storage.getFromDB(this.strBase, 'clientList');
    if (!dataVehicles || !content ) {
      return;
    }
    this.emitVehicles.emit(dataVehicles.content);

    this.print(content);
  }

  async reloadSelectedOptions(type: string) {
    const data = this.storage.get(this.strBase, type);
    const val = {value : data};
    this.controls[type] = val.value;

    switch (type) {
      case 'clients':
        await this.doChangeClients(val.value) ;
        this.reloadSelectedOptions('groups');
        break;
      case 'groups':
        await this.selectChange(val, type);
        this.reloadSelectedOptions('fleets');
        break;
      case 'fleets':
        await this.selectChange(val, type);
        this.reloadSelectedOptions('vehicles');
        break;
      case 'vehicles':
        this.controls.vehicles = data ? data : [];
        this.changeVehicles(this.controls.vehicles);
        break;
      default:
        break;
    }

    return true;
  }

  toggleFilter() {
    this.filtros = !this.filtros;
  }

  async doChangeClients(clients: number[] = []) {
    this.loadingData = true;
    this.selects.clientsSelected = clients;
    this.allVehicles = [];

    if ( !clients) {
      return false;
    }

    for (const client of clients) {
      const {groups, fleets, vehicles} = await this.loadFilters(client);
      this.filters.vehicles.set(client, vehicles);
      this.allVehicles = this.allVehicles.concat(vehicles);
      this.filters.groups.set(client, groups);
      this.filters.fleets.set(client, fleets);
    }

    await this.refreshSelects(this.selects.clientsSelected);
    if (this.first ) {
      this.first = false;
    }

    this.loadingData = false;
    this.loadZones(clients);
    this.loadPoints(clients);
    const clientsToSave = this.clients.filter(cl => clients.includes(cl.id));
    this.emitClients.emit(clientsToSave);
    this.storage.save(this.strBase, 'cls', clientsToSave);

    this.filtroOpcionesVehiculos = '';

    return true;
  }

  loadZones( clients: number[] = [] ) {
    this.zones = [];
    for( const cl of clients) {
      this.zonesService.getZones(cl)
      .subscribe((res: any) => {
        this.zones = this.zones.concat(res.lista);
      });
    }

    setTimeout(() => {
      this.emitZones.emit(this.zones);
    }, 5000);
  }

  async loadPoints( clients: number[] = [] ) {
    this.points = [];
    for ( const cl of clients) {
      this.pointsService.getPoints(cl).subscribe((data: any) => {
        const parser = new xml2js.Parser({ strict: false, trim: true });
        parser.parseString(data, (err, result) => {
          this.points = this.points.concat(result.CHECKPOINTS.CHECKPOINT);
          this.emitPoints.emit(this.points);
        });
      });
    }
  }

  async selectChange(val: any, type: string, manual = false) {
    let selection = [];
    this.filtroOpcionesVehiculos = '';

    switch(type) {
      case 'clients':
        await this.doChangeClients(val);
        selection = val;
        this.isClientConfigurate();
        break;
      case 'groups':
        this.selects.groupsSelected = val.value;
        selection = this.selects.groupsSelected;
        await this.refreshSelects(this.selects.clientsSelected, false);
        break;
      case 'fleets':
        this.selects.fleetsSelected = val.value;
        selection = this.selects.fleetsSelected;
        setTimeout(() => {
          this.refreshSelects(this.selects.clientsSelected, false);
        }, 200);
        break;
      case 'vehicles':
        this.changeVehicles(val.value);
        selection = this.controls.vehicles;
        this.selectedVehicles = val.value;
        break;
      default:
        break;
    }
    if (type !== 'vehicles') {
      if (this.selectOptions && this.selectOptions.vehicles !== undefined && this.selectOptions.vehicles[0] !== undefined )  {
          let opts: any = this.selectOptions.vehicles[0].items;
          opts.sort((a,b) => a.patente.localeCompare(b.patente));
      }
      if (manual) {
        setTimeout(() => {
          let opts = [];
          for (const opt of this.selectOptions.vehicles) {
            opts.sort((a, b) => a.patente.localeCompare(b.patente));
            opts = opts.concat(opt.items);
          }
          const visibles = opts.filter(opt => this.controls.vehicles.includes(opt.id));
          selection = this.controls.vehicles;
          this.changeVehicles(visibles.map(v => v.id));
        }, 1000);
      }
    }
    const allUserFilters = JSON.parse(localStorage.getItem('allFilters')) || {};
    allUserFilters[this.idUserLoginOpen] = {
      selects: this.selects,
      selectOptions: this.selectOptions
    };
    localStorage.setItem('allFilters', JSON.stringify(allUserFilters));

    this.saveSelection(selection, type);
  }

  saveSelection(val: any, type: string) {
    this.storage.save(this.strBase, type, val);
  }

  lookForIdsSelected() {
    setTimeout(() => {
      const updatedList = [];
      for (const id in this.vehiclesIdsSelected) {
        if ( this.vehiclesIdsSelected[id] && this.vehiclesIdsSelected[id] !== undefined) {
          updatedList.push(Number(id));
        }
      }
      this.updateMapList(updatedList);
    }, 500);
  }

  updateMapList(ids: number[]) {
    this.filteredVehicles = this.allVehicles.filter(({id}) => ids.includes(id));
    this.emitVehicles.emit(this.filteredVehicles);
  }

  getClientName(client: number) {
    for (const cl of this.clients) {
      if (cl.id === client) {
        return cl.descripcion;
      }
    }
  }

  async refreshSelects(clients: number[], reloadFilters = true) {
    try {
      if (reloadFilters) {
        this.selectOptions.groups = await this.getGroupsSelectOptions();
        this.selectOptions.fleets = await this.getFleetsSelectOptions();
      }

      const { vehiclesByClient } = await this.joinAllFiltersOnList(clients);

      if (!vehiclesByClient) {
        console.error('vehiclesByClient es undefined o null');
        return false;
      }

      this.selectOptions.vehicles = vehiclesByClient;

      if (this.selectOptions.vehicles) {
        for (let x = 0; x < this.selectOptions.vehicles.length; x++) {
          if (this.selectOptions.vehicles[x] && this.selectOptions.vehicles[x].items) {
            this.selectOptions.vehicles[x].items.sort((a, b) => a.patente.localeCompare(b.patente));
          } else {
            console.error('vehicles[x] o vehicles[x].items es undefined o null');
          }
        }
      } else {
        console.error('selectOptions.vehicles es undefined o null');
      }

      return true;
    } catch (error) {
      console.error('Error al refrescar los selects:', error);
      return false;
    }
  }


  async getFleetsSelectOptions() {
    return this.clients.filter(cl => this.selects.clientsSelected.includes(cl.id)).map((cl: SelectGroup) => {
        return {
          id: cl.id,
          name: cl.name,
          items: this.filters.fleets.get(cl.id)
        };
    });
  }

  async getGroupsSelectOptions() {
    return this.clients.filter(cl => this.selects.clientsSelected.includes(cl.id)).map((cl: SelectGroup) => {
        return {
          id: cl.id,
          name: cl.name,
          items: this.filters.groups.get(cl.id)
        };
    });
  }

  async joinAllFiltersOnList(clients: number[] = [], printing = false ) {
    const clientList = [];
    const vehiclesByClient = [];

    if (!clients) {
      return {clientList, vehiclesByClient};
    }

    for (const client of clients) {
      const vehiclesMap = {};
      if (this.selects.clientsSelected.includes(client)) {
        const clientGroup: SelectGroup = new SelectGroup();
        clientGroup.id = client;
        clientGroup.name = this.getClientName(client);
        const grs = this.filters.groups !== undefined ? this.filters.groups.get(client) : [];
        if (!grs) {
          return {clientList: [], vehiclesByClient: []};
        }
        for (const group of grs) {
          if (this.selects.groupsSelected && this.selects.groupsSelected.includes(group.id)) {
            const groupGroup: SelectGroup = new SelectGroup();
            groupGroup.id = group.id;
            groupGroup.name = group.nombre;

            for (const fleet of this.filters.fleets.get(client)) {
              if ( this.selects.fleetsSelected.includes(fleet.id)) {
                const vehiclesList = [];
                for (const vh of this.filters.vehicles.get(client)) {
                  if ( (!printing || (printing && this.controls.vehicles.includes(vh.id)))
                    && this.selects.groupsSelected.includes(vh.idGrupo) && this.selects.fleetsSelected.includes(vh.idClase)) {
                    // para dejarlos en memoria y recuperarlos con el id
                    vehiclesMap[vh.id] = vh;

                    this.allVehiclesMap.set(vh.id, vh);
                    // Guarda los vehiculos para que aparezcan en el select de vehiculos
                    vehiclesList.push(vh);
                  }
                }

                if (vehiclesList.length > 0) {
                  vehiclesList.sort((a,b) => a.patente.localeCompare(b.patente));
                  const fleetGroup: SelectGroup = new SelectGroup();
                  fleetGroup.id = fleet.id;
                  fleetGroup.name = fleet.name;
                  fleetGroup.items = vehiclesList;
                  groupGroup.items.push(fleetGroup);
                }

              }
            }
            clientGroup.items.push(groupGroup);
          }
        }
        clientList.push(clientGroup);
      }
      const clientGroup2: SelectGroup = new SelectGroup();
      clientGroup2.id = client;
      clientGroup2.name = this.getClientName(client);
      clientGroup2.items = Object.values(vehiclesMap);
      vehiclesByClient.push(clientGroup2);
    }
    return { clientList, vehiclesByClient };
  }

  async changeVehicles(ids: number[]) {
    const vehicles = ids.map(id => this.allVehiclesMap.get(id));
    if(!vehicles || vehicles.length === 0) {
      return;
    }
    this.emitVehicles.emit(vehicles);
    setTimeout(() => {
      this.addListeners(vehicles);
    }, 1000);
    const { clientList } = await this.joinAllFiltersOnList(this.selects.clientsSelected, true);

    this.saveSelection(clientList, 'clientList');
    this.saveSelection(vehicles, 'vehiclesToShow');
    this.print(clientList);
  }

  addListeners(list: Vehiculo[]) {
    list.forEach((v: Vehiculo) => {
      if (v === undefined) {
        return;
      }
      const div = document.getElementById('lbl' + v.id);
      if (div !== null) {
        div.addEventListener('click', (event) => {
          this.setFocus.emit(v.id);
        });
      }
    });
  }

  print(list) {
    let vehs = [];
    const divClientes = document.getElementById('clientes');
    divClientes.innerHTML = '';
    for ( const client of list) {
      const id1 = client.id;
      let divClient = document.getElementById(id1);
      if (divClient === null ) {
         divClient = this.createDiv(id1);
         divClient.innerHTML = client.name;
         divClient.className = 'div-client';
      }

      for ( const group of client.items) {
        const id2 = `${id1}-${group.id}`;
        let divGroup = document.getElementById(id2);
        if (divGroup === null ) {
          divGroup = this.createDiv(id2);
          divGroup.innerHTML = group.name;
          divGroup.className = 'div-group';
        }

        for (const fleet of group.items) {
          const id3 = `${id2}-${fleet.id}`;
          let divFleet = document.getElementById(id3);
          if (divFleet === null ) {
            divFleet = this.createDiv(id3);
            divFleet.innerHTML = fleet.name;
            divFleet.className = 'div-fleet';
          }
          const vehiclesList = fleet.items.filter(vehicle => vehicle.idGrupo === group.id && vehicle.idClase === fleet.id);
          for (const vehicle of vehiclesList ) {
            const id4 = 'v-' + vehicle.id;
            const divVehicle = this.createDiv(id4);
            const chkb = this.createDiv('chk-' + vehicle.id, 'input');
            const label = this.createDiv('lbl' + vehicle.id, 'label');
            label.setAttribute('for', 'chk-' + vehicle.id);
            label.innerHTML = vehicle.descripcion.length > 2 ? vehicle.descripcion :  vehicle.patente;
            divVehicle.appendChild(label);
            chkb.setAttribute('type', 'checkbox');
            chkb.setAttribute('name', 'chckbx');
            chkb.setAttribute('value', 'true');
            chkb.className = 'chkb';
            divVehicle.className = 'div-vehicle';
            divFleet.appendChild(divVehicle);

          }
          if (vehiclesList.length > 0) {
            vehs = vehs.concat(vehiclesList);
            divGroup.appendChild(divFleet);
          }
        }
        if (divGroup.childNodes.length > 1) {
          divClient.appendChild(divGroup);
        }
      }
      if (divClient.childNodes.length > 1) {
        divClientes.appendChild(divClient);
      }
    }

    this.addListeners(vehs);
  }

  createDiv(id, type = 'div') {
    const element = document.createElement(type);
    element.id = id;
    return element;
  }

  filterRepeteatedVehicles2(arr: Vehiculo2[]) : Vehiculo2[] {
    let tmp: Vehiculo2[] = [];
    // validar que sea más reciente y que tenga valores únicos
    arr.forEach((item ,_, self) => {
      const found = self.filter(x => x.id === item.id);
      if(found.length > 1) {
        const millis = found.map(x => x.point.millis);
        const maxMillis = Math.max(... millis);
        const mostRecent = found.filter(x => x.point.millis === maxMillis );
        tmp.push(mostRecent[0]);
      } else {
        tmp.push(item);
      }
    });
    return tmp.filter((value,index,self) =>
      index === self.findIndex(x => (x.id === value.id && x.point.millis === value.point.millis)));
  }

  updateArray(arr1: any[], arr2: any[]): any[] {
    if (!arr1 || !arr2) {
      return [];
    }

    return arr1.map(veh => {
      const found = arr2.find(v => v.id === veh.id);
      if (found) {
        const element = arr2.filter(v => v.id === veh.id);
        return element[0];
      }
      return veh;
    });
  }


  async loadFilters(client: number) {
    let groups: any;
    let fleets: any;
    let vehicles: any;

    groups = this.filters.groups.get(client);
    if (groups === undefined || groups === null) {
      groups = await this.loadGroups(client);
    }

    fleets = this.filters.fleets.get(client);
    if (fleets === undefined || fleets === null) {
      fleets = await this.loadFleets(client);
    }

    vehicles = await this.filters.vehicles.get(client);
    if (vehicles === undefined || vehicles === null) {
      vehicles = await this.loadVehicles(client);
      const vehicleIds = vehicles.map(registro => registro.id);
      const vehicleIdsString = vehicleIds.join(',');
      try {
        await this.socketService.connect();
      } catch (error) {
        console.error('Failed to connect to websocket',error);
        return;
      }
      this.socketService.sendMessage(vehicleIdsString);
      this.subscriptions.push(
        this.socketService.messages.subscribe(message => {
          try {
            const messageData = JSON.parse(message);
            if (!messageData) {
              return;
            }
            const gpsDataArray = messageData.data.map((item: { gpsData: any; }) => item.gpsData);
            let vehicles2: Vehiculo2[] = gpsDataArray.map((v: any) => {
              const veh = new Vehiculo2();
              veh.setData(v);
              return veh;
            });
            vehicles2 = this.filterRepeteatedVehicles2(vehicles2);
            for (const cl of this.selects.clientsSelected) {
              this.allVehicles = this.allVehicles !== undefined ? this.updateArray(this.allVehicles, vehicles2) : [];
              let tmp = this.filters.vehicles.get(cl);
              tmp = this.updateArray(tmp, vehicles2);
              this.filters.vehicles.set(cl,tmp);
            }
            if (this.selects.clientsSelected && this.selects.clientsSelected.length > 0) {
              let opts = [];
              for (const opt of this.selectOptions.vehicles) {
                opts.sort((a, b) => a.patente.localeCompare(b.patente));
                opts = opts.concat(opt.items);
              }
              const visibles = opts.filter(opt => this.controls.vehicles.includes(opt.id));
              this.changeVehicles(visibles.map(v => v.id));
            }

          } catch (error) {
            console.error('Error al parsear el mensaje del WebSocket:', error);
          }
        })
      );
      vehicles.sort((a, b) => a.patente.localeCompare(b.patente));
      vehicles = vehicles.map((v: Vehiculo) => {
        return {
          ...v,
          client
        };
      });
      this.allVehiclesAlert = vehicles;
    }
    return {groups, fleets, vehicles};
  }

  async loadAlerts() {
    if (!this.clientConfigurate) {
        return;
    }
      // Crear las fechas
      const endDate = new Date().getTime();
      const startDate = endDate - 1800000; // 30 minutos antes

      const alertRequestObjects: AlertRequest[] = [];
      // Hacer una petición getAlert para cada grupo de idClient
      const alertPromises = Array.from(this.filters.vehicles.entries()).map(([idClient, vehicles]) => {
        const vehicleIds = vehicles.map(vehicle => vehicle.id);
        const alertRequest: AlertRequest = {
          idVehiclesFilter: vehicleIds,
          startDateFilter: startDate,
          endDateFilter: endDate,
          idClient: +idClient,
        };
        alertRequestObjects.push(alertRequest);
        return this.vehiclesService.getAlert(alertRequest);
      });
      const resAlert: AlertsByClient[] = [];
      try {
        const allAlertResponses = await Promise.all(alertPromises);

        allAlertResponses.forEach((alerts, index) => {
          resAlert.push({
            alerts,
            idClient: alertRequestObjects[index].idClient,
          });
        });
        this.emitAlerts.emit(resAlert);

      } catch (error) {
        console.error('Error cargando alertas:', error);
      }

  }
  isClientConfigurate() {
    this.clientConfigurate = this.selects.clientsSelected.some(id => {
      return this.idClientConfigurate.includes(id);
    });

    this.clientConfigurateOnRoute = this.selects.clientsSelected.some(id => {
      return this.idClientConfigurateOnRoute.includes(id);
    });

  }
  async getClientConfigurateWithTxt() {
    try {
      const data = await this.http.get('./configFlotaCompleta.txt', { responseType: 'text' }).toPromise();

      const ids = data.split(',').map(id => Number(id.trim())); // asume que los IDs están separados por comas
      this.idClientConfigurate = ids;
    } catch (error) {
      console.log(error);
    }
  }
  async getClientConfigurateOnRouteWithTxt() {
    try {
      const data = await this.http.get('./configFlotaCompletaOnRoute.txt', { responseType: 'text' }).toPromise();
      const ids = data.split(',').map(id => Number(id.trim())); // asume que los IDs están separados por comas
      this.idClientConfigurateOnRoute = ids;
    } catch (error) {
      console.log(error);
    }
  }
  async loadGroups(client: number) {
    return this.groupsService.getGrupos(client);
  }

  async loadFleets(client: number) {
    return this.fleetsService.getFleets(client);
  }

  async loadVehicles(client: number) {
    return this.vehiclesService.getVehiclesList(client);
  }

  returnError(error) {
    const e = new Error();
    e.message = error;
    throw e;
  }

  async toggleMapOptions(event: MapOptions) {
    const mapOptions = new MapOptions();
    mapOptions.automaticFit = event.automaticFit;
    mapOptions.showCluster = event.showCluster;
    mapOptions.showDirections = event.showDirections;
    mapOptions.showLabels = event.showLabels;
    mapOptions.showPoints = event.showPoints;
    mapOptions.showTraffic = event.showTraffic;
    mapOptions.showZones = event.showZones;
    mapOptions.showCog = event.showCog;
    mapOptions.onRoute = event.onRoute;
    if (event.onRoute) {
      await this.getVEhicleOnRoute();
    } 
    this.emitMapOptions.emit(mapOptions);
  }
  async getVEhicleOnRoute() {
      // Crear un arreglo de observables
    for (const cl of this.selects.clientsSelected) {
      const observables = this.filters.vehicles.get(cl).map(vehicle => this.vehiclesService.isVehicleOnRoute(vehicle.id).pipe(
        map((response: any) => ({id: vehicle.id, success: response.data.onRoute} as {id: number, success: boolean})),
        catchError(() => of({id: vehicle.id, success: false}))
      ));

      // Convertir forkJoin a Promesa y esperar a que se complete
      const responses: Array<{ id: number; success: boolean }> = await forkJoin(observables).toPromise() as Array<{ id: number; success: boolean }>;

      // Filtrar los vehículos que tuvieron éxito
      const vehicle = this.filters.vehicles.get(cl).filter(vehicle => {
        const vehicleResponse = responses.find(response => response.id === vehicle.id);
        return vehicleResponse && vehicleResponse.success;
      });
      const ids: number[] = vehicle.map(vehicle => vehicle.id);
      await this.changeVehicles(ids);
      this.controls.vehicles = ids;
      this.saveSelection(this.controls.vehicles, 'vehicles');
    }
    await this.reloadSelectedOptions('vehicles');
    await this.refreshSelects(this.selects.clientsSelected);
    // Por alguna razón no usaban esta función, esto arregla algunas cosas como la opción En Ruta.
    await this.reloadLists();
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  async selectAll(type: string)  {
    let concated = [];

    switch(type) {
      case 'clients':
        break;
      case 'groups':
        for (const c of this.selectOptions.groups) {
          concated = concated.concat(c.items.map(({id}) => id));
        }
        this.controls.groups = concated;
        this.selectChange({value: this.controls.groups}, type, true);
        break;
      case 'fleets':
        concated = [];
        for (const c of this.selectOptions.fleets) {
          concated = concated.concat(c.items.map(({id}) => id));
        }
        this.controls.fleets = concated;
        this.selectChange({value: this.controls.fleets}, type, true);
        break;
      case 'vehicles':
        concated = [];
        for (const c of this.selectOptions.vehicles) {
          concated = concated.concat(c.items.map(({id}) => id));
        }
        this.controls.vehicles = concated;
        this.saveSelection(concated, 'vehicles');
        break;
      default:
        break;
    }

    if (type !== 'vehicles') {
      await this.refreshSelects(this.controls.clients, true);
    }
    this.selectChange({value: this.controls.vehicles}, 'vehicles', true);
  }

  deselectAll(type: string)  {
    switch(type) {
      case 'clients':
        break;
      case 'groups':
        this.controls.groups = [];
        this.selectChange({value:this.controls.groups}, type, true);
        break;
      case 'fleets':
        this.controls.fleets = [];
        this.selectChange({value: this.controls.fleets}, type, true);
        break;
      case 'vehicles':
        this.controls.vehicles = [];
        this.selectChange({value: this.controls.vehicles}, type);
        this.saveSelection(this.controls.vehicles, type);
        break;
      default:
        break;
    }
    if (type !== 'vehicles') {
      this.refreshSelects(this.controls.clients, true);
    }
  }
}



class Filters {
  clients = [];
  groups = new Map();
  fleets = new Map();
  vehicles = new Map();
}

class Selects {
  groups = [];
  fleets = [];
  vehicles = [];

  clientsSelected = [];
  groupsSelected = [];
  fleetsSelected = [];
}

class SelectGroup {
  id = 0;
  items = [];
  name = '';
}

class FiltersEntity {
  clients = {};
  groups = {};
  fleets = {};
}


class Controls {
  clients = [];
  groups = [];
  fleets = [];
  vehicles = [];
}


class SelectGroups {
  groups = [];
  fleets = [];
  vehicles = [];
}

class SelectOptions {
  client = [];
  groups = [];
  fleets = [];
  vehicles = [];
}
