import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: WebSocket;
  public messages: Subject<string> = new Subject<string>();
  private shouldReconnect = true;
  private reconnectionDelay = 5000;
  private listeningVehicles: string;
  private messageInterval;

  constructor() {}

  public async connect(): Promise<void> {
    this.shouldReconnect = true;

    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        console.log('Using existing WebSocket connection');
        resolve();
        return;
      }

      const url = `${environment.endPointApiWebsocket}?at=${localStorage.getItem('at')}`;
      this.socket = new WebSocket(url);

      this.socket.onopen = () => {
        console.log('Conexión exitosa');
        resolve();
        if (this.listeningVehicles) {
          this.sendMessage(this.listeningVehicles);
        }
        setInterval(() => {
          if (this.listeningVehicles) {
            console.log('Enviando vehículos:', this.listeningVehicles);
            this.sendMessage(this.listeningVehicles);
          }
        }, 30 * 60 * 1000);
      };
      this.socket.onmessage = (event) => {
        //console.log("socket event.data: "+event.data)
        this.messages.next(event.data);
      };

      this.socket.onerror = (error) => {
        console.log('Error en la conexión del WebSocket: ', error);
        reject(error);
      };

      this.socket.onclose = (event) => {
        console.log('WebSocket cerrado: ', event);
        if (this.shouldReconnect) {
          setTimeout(() => this.connect(), this.reconnectionDelay);
        }
      };
    });
  }

  public sendMessage(message: string): void {
    this.listeningVehicles = message;
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.log('WebSocket is not open. Cannot send message.');
    }
  }

  public disconnect(): void {
    this.shouldReconnect = false;
    if (this.messageInterval) {
      clearInterval(this.messageInterval);
    }
    if (this.socket) {
      this.socket.close();
    }
  }
}
