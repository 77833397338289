import { Component, OnInit, EventEmitter, Output } from '@angular/core';

import { Point } from 'app/_class/point';
import { Appearance } from '@angular-material-extensions/google-maps-autocomplete';

@Component({
  selector: 'app-posicionador-mapa',
  templateUrl: './posicionador-mapa.component.html',
  styleUrls: ['./posicionador-mapa.component.css']
})
export class PosicionadorMapaComponent implements OnInit {
  @Output() enterPoint = new EventEmitter<Point>();
  @Output() enterLocation = new EventEmitter<any>();

  public appearance = Appearance;

  constructor() { }

  ngOnInit() {
  }

  loadCoordenada(data: any) {
    const arr = data.srcElement.value.trim().split(',');
    const isCoordenada = arr.length === 2 && !isNaN(arr[0]) && !isNaN(arr[0]);
    if (isCoordenada) {
      const punto = new Point(arr[0], arr[1]);
      this.enterPoint.emit(punto);
    }
  }

  onLocationSelected(location: any) {
    this.enterLocation.emit({
      point: new Point(location.geometry.location.lat(), location.geometry.location.lng()),
      address: location.formatted_address
    });
  }

}
