import { Zona } from './../../../_class/zona';
import { ZonesService } from './../../../_services/zones/zones.service';
import { GrupoZona } from './../../../_class/grupo-zona';
import { FleetsService } from './../../../_services/fleets/fleets.service';
import { GroupsService } from './../../../_services/groups/groups.service';
import { Component, OnInit, EventEmitter, ViewChild, Output, Input, OnDestroy } from '@angular/core';
import { AgmMarker } from '@agm/core/directives';
import { PageEvent, MatDialog, MatDialogConfig } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { Subject, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { InputDetalleMapa, DetalleMapaComponent } from 'app/_components/detalle-mapa/detalle-mapa.component';

import { PermisoClass } from 'app/_class/permiso';
import { LoginService } from 'app/_services/login.service';
import { RegistroHC } from 'app/_class/registro-hc';
import { Permiso } from 'app/_interfaces/permiso';
import { environment } from 'environments/environment';
import { DataService } from 'app/_services/data.service';
import { Meta } from 'app/_interfaces/meta';

import { Vehiculo } from 'app/_class/vehiculo';
import { Appearance } from '@angular-material-extensions/google-maps-autocomplete';

// IMPORTS PARA EL TIMER
import { interval } from 'rxjs/observable/interval';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
import { RealtimeDataService } from 'app/_services/realtimeData/realtime-data.service';
import { FlotaActiva } from 'app/_class/indicadores/flota-activa';
import { Alerta } from 'app/_class/indicadores/alerta';
import { Actividad } from 'app/_class/indicadores/actividad';
import { FormControl } from '@angular/forms';
import { MapOptions } from 'app/_class/map-options';
import { Point } from 'app/_class/point';
import { StorageService } from 'app/_services/storage/storage.service';
import { SocketService } from '../../../_services/vehicles/socketService .service';
import { Vehiculo2 } from '../../../_class/vehiculo2';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';


const refreshingVehiclesTimeInterval = 20000; // 20000;

export interface VehiculosFilter {
  patente: string[];
  nombreFlota: string[];
  nombreGrupo: string[];
}

@Component({
  selector: 'app-index-dashboard',
  templateUrl: './index-dashboard.component.html',
  styleUrls: [ './index-dashboard.component.css' ]
})

export class IndexDashboardComponent implements OnInit, OnDestroy {
  private alive = true;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  private strFleet = 'fleet';
  private strGroup = 'group';
  private strVehicle = 'vehicle';
  private strBase = 'dash';

  private first = true;

  // Grupos  para el cliente actualmente seleccionado
  titleDashboard = 'Tranciti | Dashboard'
  titleFlotaCompleta = 'Tranciti | Modo Flota Completa'
  groups: {};
  groupsOptions = [];
  fleetsOptions = [];
  selectedGroups = [];
  selectedFleets = [];
  vehiclesOptions = [];
  vehiclesSelected = [];
  showList = [];
  fleets: {};
  idCliente: number; // id cliente seleccionado
  idsClienteConfiguration: number[] = [];
  idsClienteFlotaConfiguration: number[] = [];
  $updateDireccion = new Subject<number>();
  pageEvent: PageEvent = null;
  pageSize = 5;
  dataTable: RegistroHC[] = [];
  dataTableVisible = [];
  /** Nativa Map Object */
  mapOptions = new MapOptions();
  markers = {};
  markersDashboard = [];
  markersFlotaCompleta = [];
  vehicles = [];
  focus = {lat: -33.428480, lng: -70.609770, id: null};
  displayedColumns: string[] =  [ 'patente', 'grupo', 'conductor' , 'flota', 'ubicacion'  , 'estado', 'accion' ];
  displayedColumnsWithBattery: string[] = [ 'patente' , 'grupo' , 'conductor', 'flota', 'ubicacion', 'bateria', 'estado', 'accion' ];
  positionSetted = null;
  inputDetalleMapa: InputDetalleMapa;

  @Output() emitterToggleSB = new EventEmitter<boolean>();
  modoFlota = false;
  newServiceApi = false;
  dataSource: MatTableDataSource<Vehiculo>;
  alreadyLoadLatest = false;
  filterValue = '';
  vehiclesDataMap: {};
  // Indicadores
  iFlota = new FlotaActiva();
  iAlerta = new Alerta();
  iActividad = new Actividad();
  startIndex = 0;
  endIndex = 5;
  previousPageIndex = -1;
  vehiculosControl = new FormControl();
  gruposControl = new FormControl();
  flotasControl = new FormControl();
  filteredValues: VehiculosFilter = {patente: [], nombreFlota: [], nombreGrupo: []};
  s1: Subscription;
  s2: Subscription;
  s3: Subscription;
  zoneGroups: GrupoZona[] = [];
  zones: Zona[] = [];
  private loadIntervalSubscription: Subscription;

  direcciones = {};

  doingChanges = false;

  @Input() set cliente(id: number) {
    this.idCliente = id;
  }

  public appearance = Appearance;
  private subscriptions: Subscription[] = [];

  constructor(
    private dataService: DataService,
    private loginService: LoginService,
    private groupsService: GroupsService,
    private fleetService: FleetsService,
    private zoneService: ZonesService,
    public dialog: MatDialog,
    private storage: StorageService,
    private realtimeDataService: RealtimeDataService,
    private socketService: SocketService,
    private readonly titleService:Title,
    private http: HttpClient) {
  }

  appVersion(){
    return environment.appVersion;
  }
  
  initComponent() {
    this.getClientConfigurateWithTxt().then(value => true);
    this.newServiceApi = false;
    this.subscribe();


    this.loginService.activarService();
    this.s2 = this.loginService.$idCliente.subscribe(id => {
      this.idCliente = id;
      this.dataService.$idCliente.next(id);
      if ( id > 0 ) {
        this.updateView();
      }
    });

    this.s3 = this.dataService.$idCliente.subscribe(id => {
      if ( id > 0 ) {
        console.log('llega primero?')
        this.updateView();
        this.loadInterval();
      }
    });
  }
  ngOnInit() {
    this.initComponent();
  }
  ngOnDestroy(): void {
    this.alive = false;
    this.s1.unsubscribe();
    this.s2.unsubscribe();
    this.s3.unsubscribe();
    if (this.loadIntervalSubscription) {
      this.loadIntervalSubscription.unsubscribe();
    }
    this.socketService.disconnect();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

  }
  async getClientConfigurateWithTxt() {
    try {
      const data = await this.http.get('./configDashboard.txt', { responseType: 'text' }).toPromise() ;
      console.log('txt', data);

      const ids = data.split(',').map(id => Number(id.trim())); // asume que los IDs están separados por comas
      this.idsClienteConfiguration = ids;
    } catch (error) {
      console.log(error);
    }
  }

  async getClientConfigurateFlotaWithTxt() {
    try {
      const data = await this.http.get('./configFlotaCompleta.txt', { responseType: 'text' }).toPromise();
      console.log('txt', data);

      const ids = data.split(',').map(id => Number(id.trim())); // asume que los IDs están separados por comas
      this.idsClienteFlotaConfiguration = ids;
    } catch (error) {
      console.log(error);
    }
  }

  checkUserTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  //limpia los datos asociados al idcliente para vaciar los selectores, la lista de vehiculos y el mapa
  cleanData(){
    this.markers = {};
    this.dataSource = new MatTableDataSource<Vehiculo>();
    // limpiar grupos, flotas, vehiculos seleccionados al cambiar de cliente
    this.selectedGroups = [];
    this.selectedFleets = [];
    this.vehiclesSelected = [];
    this.markersDashboard = [];  // Remueve todos los marcadores
    const userTimezone=this.checkUserTimezone()
    if (userTimezone === "America/Mexico_City") {
      this.focus = {lat: 19.432608, lng: -99.133209, id: null}; //ciudad de mexico
    } else {//"America/Santiago"
      this.focus = {lat: -33.428480, lng: -70.609770, id: null}; //stgo
    }
  }

  subscribe() {
    this.socketService.disconnect();
    this.s1 = this.dataService.$idCliente.subscribe(async (id) => {
      const savedClient = this.storage.get(this.strBase, 'client');
      console.log('--------------change client, ', savedClient, id);
      if ( !this.first && savedClient !== id ) {
        this.storage.deleteAllSelections(this.strBase);
      }
      this.idCliente = id;
      this.cleanData()
      await this.getGroups();
      await this.getFleets();
      this.loadZones();

      this.storage.save('dash', 'client', id);
      this.socketService.disconnect();

      this.realtimeDataService.getVehicleByIdVehicle(this.idCliente, !this.idsClienteFlotaConfiguration.includes(id)).subscribe(async (data: any) => {
        this.alreadyLoadLatest = false;
        const vehicleIds = data.data.map(registro => registro.vehicleId);
        const vehicleIdsString = vehicleIds.join(',');
        // console.log("mucha data:",data)

        try {
          await this.socketService.connect();  // Wait for the promise to resolve
        } catch (error) {
          console.error('Failed to connect to WebSocket', error);
          return;  // Exit early if connection failed
        }
        this.socketService.sendMessage(vehicleIdsString);
        this.newServiceApi = true;
        this.subscriptions.push(
          this.socketService.messages.subscribe(message => {
            try {
              const messageData = JSON.parse(message);
              const gpsDataArray = messageData.data.map((item: { gpsData: any; }) => item.gpsData);
              if (this.alreadyLoadLatest) {
                this.parseVehicleData(gpsDataArray, false, true);
              }
            } catch (error) {
              console.error('Error al parsear el mensaje del WebSocket:', error);
            }
          })
        );
        this.parseVehicleData(data.data, true);
        this.alreadyLoadLatest = true;
      });
      
    });
  }

  loadZones() {
    this.zoneService.getGroups(this.idCliente).subscribe((d: any) => this.zoneGroups = d.lista);
    this.zoneService.getZones(this.idCliente).subscribe((d: any) => {
      this.zones = d.lista.map((z: Zona) => {
        return {
          ...z
        };
      });
    });
  }

  setSelect(event, type) {
    if ( event === null ) {
      event = [];
    }
    switch ( type ) {
      case 'group':
        if ( !this.first ) {
          this.selectedGroups = event;
          this.storage.deleteOne(this.strBase, 'fleet');
          this.storage.deleteOne(this.strBase, 'vehicle');
        }
        this.doFilter(event, this.groups, 'nombreGrupo');
        this.getFleets();
        this.setVehiclesOptions();
        break;
      case 'fleet':
        if ( !this.first ) {
          this.selectedFleets = event;
          this.storage.deleteOne(this.strBase, 'vehicle');
        }
        this.doFilter(event, this.fleets, 'nombreFlota');
        this.setVehiclesOptions();
        break;
      case 'vehicle':
        if ( !this.first ) {
          this.vehiclesSelected = event;
        }
        break;
      default:
        break;
    }

    this.storage.save(this.strBase, type, event);
    this.updateSelection();
  }

  updateSelection() {
    const idsOptions = this.vehiclesOptions.map(o => o.id);
    this.vehiclesSelected = this.vehiclesSelected.filter(v => idsOptions.includes(v));
    this.doDashboardFilter('patente', this.vehiclesSelected.map(e => this.vehicles.filter(v => v.id === e)[0]).map(v => v.patente));
    this.updateView();
  }

  async setVehiclesOptions() {
    this.filterVehiclesOptions().then((res: any[]) => this.vehiclesOptions = res);
    return true;
  }

  async filterVehiclesOptions() {
    return new Promise(async (resolve, reject) => {
      this.vehiclesOptions = this.vehicles.filter(veh =>
        (this.selectedGroups.length === 0 || this.selectedGroups.includes(veh.idGrupo)) &&
        (this.selectedFleets.length === 0 || this.selectedFleets.includes(veh.idClase)))
        .map(veh => ({id: veh.id, descripcion: `${veh.patente} ${veh.descripcion}`}));

      if ( this.first ) {
        this.first = false;
        const restored = this.storage.get(this.strBase, this.strVehicle);
        this.vehiclesSelected = restored ? restored : [];
        const fleets = this.storage.get(this.strBase, this.strFleet);
        const groups = this.storage.get(this.strBase, this.strGroup);
        if ( fleets ) {
          this.doFilter(fleets, this.fleets, 'nombreFlota');
        }

        if ( groups ) {
          this.doFilter(groups, this.groups, 'nombreGrupo');
        }

        this.updateSelection();
      }
      resolve(this.vehiclesOptions);
    });
  }

  doFilter(event: any[], object: {}, type: string) {
    this.doDashboardFilter(type, event.map(res => object[res]));
  }

  loadInterval() {
    if (this.loadIntervalSubscription) {
      this.loadIntervalSubscription.unsubscribe();
    }
    this.loadIntervalSubscription = interval(refreshingVehiclesTimeInterval)
      .pipe(takeWhile(() => this.alive && !this.modoFlota && !this.newServiceApi))
      .subscribe(() => {
        this.getVehiclesData();
      });
  }

  async getVehiclesData(doSetOptions = false) {
    if ( this.idCliente !== undefined ) {
      this.realtimeDataService.getVehiclesList(this.idCliente)
        .subscribe((data: any) => {
          this.parseVehicleData(data, doSetOptions);
        });
    }

    return true;
  }




  async getGroups() {
    this.groups = {};
    this.groupsOptions = [];
    const svdgroups = 'svdgrps';

    const savedGroups = this.storage.get(this.strBase, svdgroups);
    let gr;
    if ( !savedGroups ) {
      gr = await this.groupsService.getGrupos(this.idCliente);
      this.storage.save(this.strBase, svdgroups, {groups: gr, time: new Date().getTime()});
    } else {
      gr = savedGroups.groups;
    }
    gr.forEach(({id, nombre}) => {
      if ( this.groups[id] === undefined ) {
        this.groups[id] = nombre.trim();
        this.groupsOptions.push({id, descripcion: nombre});
      }
    });
    if ( this.first ) {
      const groups = this.storage.get(this.strBase, this.strGroup);
      if ( groups ) {
        this.selectedGroups = groups;
      }
    }
  }

  async getFleets() {
    this.fleets = {};
    this.fleetsOptions = [];

    if ( this.selectedGroups.length > 0 ) {
      for ( const gr of this.selectedGroups ) {
        const fleets = await this.fleetService.getFleets(this.idCliente, gr);
        await this.parseFleets(fleets);
      }
    } else {
      const fleets = await this.fleetService.getFleets(this.idCliente);
      await this.parseFleets(fleets);
    }

    if ( this.first ) {
      const fleets = this.storage.get(this.strBase, this.strFleet);
      if ( fleets ) {
        this.selectedFleets = fleets;
      }
    }

    return this.fleets;
  }

  async parseFleets(data: any = []) {
    data.forEach(async ({ id, name }) => {
      if (!this.fleets[id]) {  // Verificar si fleets[id] no existe
        this.fleets[id] = name;
        this.fleetsOptions.push({ id, descripcion: name });
      }
    });
  }

  doDashboardFilter(type: string, filteredValues: any) {
    this.filteredValues[type] = filteredValues;
    this.dataSource.filter = JSON.stringify(this.filteredValues);
    this.dataSource.filteredData.sort((a, b) => a.patente.localeCompare(b.patente));
    this.markersDashboard = this.dataSource.filteredData;
  }

  doDashboardMarkersFilter() {

  }

  updateView() {
    // Actualiza los indicadores
    this.iFlota.setLoadOff();
    this.iAlerta.setLoadOff();
    this.iActividad.setLoadOff();

    const g = this.selectedGroups;
    const f = this.selectedFleets;
    const v = this.vehiclesSelected;

    const sub1 = this.loginService.getIndicadorFlota(g, f, v).subscribe(data => {
      if (!data) {
        return;
      }
      this.iFlota.activos = data.activos;
      this.iFlota.inactivos = data.inactivos;
      this.iFlota.total = data.total;
      this.iFlota.setLoadOn();
    });

    const sub2 = this.loginService.getIndicadorAlerta(g, f, v).subscribe(data => {
      if (!data) {
        return;
      }
      this.iAlerta.total = data.total;
      this.iAlerta.setLoadOn();
    });

    const sub3 = this.loginService.getIndicadorActividad(g, f, v).subscribe(data => {
      if (!data) {
        return;
      }
      this.iActividad.lastWeek = data.lastWeek;
      this.iActividad.beforeLastWeek = data.beforeLastWeek;
      this.iActividad.setLoadOn();
    });
    this.subscriptions.push(sub1, sub2, sub3);
  }

  getBatteryImageCharging(level: number): string {
    if (level >= 0 && level <= 30) {
      return './cargando-rojo.svg';
    } else if (level > 30 && level <= 50) {
      return './cargando-amarillo.svg';
    } else if (level > 50 && level <= 100) {
      return './cargando-verde.svg';
    }
  }

  getBatteryImageNotCharging(level: number): string {
    if (level >= 0 && level <= 30) {
      return './normal-rojo.svg';
    } else if (level > 30 && level <= 50) {
      return './normal-amarillo.svg';
    } else if (level > 50 && level <= 100 ) {
      return './normal-verde.svg';
    }
    return './normal-union.svg';
  }
  getBatteryImageNeverCharged(): string {
      return './normal-union.svg';
  }

  getBatteryTextColor(level: number): string {
    if (level >= 0 && level <= 30) {
      return 'red';
    } else if (level > 30 && level <= 50) {
      return '#E8AE1B';
    } else if (level > 50 && level <= 100 ) {
      return 'green';
    }
    return '#2C2C2C';
  }

  filterIncompleteVehicles(d: any) {
    const lat = d.hasOwnProperty('lat') ? d.lat : d.latitud;
    const lng = d.hasOwnProperty('lon') ? d.lon : d.longitud;
    const imeiFilter = d.imei === null || d.imei === undefined || Number(d.imei) === 0;
    const latFilter = lat === null || Number.isNaN(lat) || lat === undefined;
    const lonFilter = lng === null || Number.isNaN(lng) || lng === undefined;

    return imeiFilter || latFilter || lonFilter;
  }

  parseVehicleData(data: any, doSetOptions = false, fromSocket = false) {
    if ( data ) {
      let i = 0;
      for ( const d of data ) {
        i++;
        if (this.filterIncompleteVehicles(d)) { 
          continue; 
        }
        const vm = this.markers[d.imei];
        let vc = new Vehiculo2();
        if ( vm !== null && vm !== undefined ) {
          vc = vm.clone();
        }
        vc.setData(d);
        vc.nombreFlota = this.getFlota(vc.idClase);
        vc.nombreGrupo = this.getGrupo(vc.idGrupo);
        if (vm === undefined || vm === null || !fromSocket) {
            // First time, set everything in markers
            this.markers[vc.imei] = vc;
        } else if (vc.point.millis > vm.point.millis) {
            // Needs to update only if the incoming record is newer
            this.markers[vc.imei].point = vc.point;
            this.markers[vc.imei].lastPoint = vc.lastPoint;
        } else {
            return;
        }
      }
    } else if ( data && data.contenido && data.contenido.registro ) {
      const arr = data.contenido.registro;
      let i = 0;
      for ( const d of arr ) {
        i++;
        const vm = this.markers[d.W2];
        let vc = new Vehiculo();
        // Si el vehiculo existe en el array de marcadores entonces obtengo ese vehiculo;
        if ( vm !== null && vm !== undefined ) {
          vc = vm;
        }
        vc.setData(d);
        vc.nombreFlota = this.getFlota(vc.idClase);
        vc.nombreGrupo = this.getGrupo(vc.idGrupo);
        if ( vm ) {
          this.markers[vc.imei].point = vc.point;
          this.markers[vc.imei].lastPoint = vc.lastPoint;
        } else {
          this.markers[vc.imei] = vc;
        }
      }
    }

    this.vehicles = Object.values(this.markers);
    if ( doSetOptions ) {
      this.setVehiclesOptions();
    }

    this.setMarkersFlotaCompleta();

    this.dataSource.data = this.vehicles;
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.paginator = this.paginator;
    this.dataSource.filteredData.sort((a, b) => a.patente.localeCompare(b.patente));
    this.markersDashboard = this.dataSource.filteredData;

    this.dataSource.paginator.page.subscribe((pageEvent: PageEvent) => {
      if ( this.previousPageIndex !== pageEvent.previousPageIndex ) {
        this.startIndex = pageEvent.pageIndex * pageEvent.pageSize;
        this.endIndex = this.startIndex + pageEvent.pageSize;
        this.cargarDirecciones();
        this.previousPageIndex = pageEvent.previousPageIndex;
      }
    });

    this.cargarDirecciones();

  }

  setMarkersFlotaCompleta() {
    this.vehicles.sort((a, b) => a.patente.localeCompare(b.patente));
    this.markersFlotaCompleta = this.showList.length === 0 ? this.vehicles : this.vehicles.filter(v => this.showList.includes(v.id));
  }

  toggleSideBar(): void {
    if (this.modoFlota) {
      this.alive = true;
      this.initComponent();
      this.titleService.setTitle(this.titleDashboard);
    } else {
      this.ngOnDestroy();
      this.titleService.setTitle(this.titleFlotaCompleta);
    }
    this.emitterToggleSB.emit(!this.modoFlota);
    this.dataService.modoFlota = !this.modoFlota;
  }


  // Carga  los permisos para  cliente  seleccionado, mostrando u ocultado componentes
  cambiarCliente(idCliente) {
    this.startIndex = 0;
    this.endIndex = 5;
    this.loginService.$idCliente.next(idCliente);
  }

  // deprecated
  getGrupo(id: number) {
    return this.groups && this.groups[id] ? this.groups[id] : '-';

  }

  getFlota(id: number) {
    return this.fleets && this.fleets[id] ? this.fleets[id] : '-';
  }

  // Retorna  los datos [filtrados] de acuerdo a la paginacion seleccionada,
  // si no posee direccion cargada se programa la carga de la direccion
  centerMap(lat = -33.428480, lng = -70.609770, id: number = null) {
    this.focus = {lat, lng, id};
  }

  mostrarInfoWindowYCentrar(vehiculo: Vehiculo) {
    this.reportOpenCloseInfoWindowOnVehicleMarker(vehiculo);
    this.vehicles.forEach((v: Vehiculo) => v.isOpenInfoWindow = (v.imei === vehiculo.imei));
    this.centerMap(Number(vehiculo.point.lat), Number(vehiculo.point.lng), vehiculo.id);
  }

  reportOpenCloseInfoWindowOnVehicleMarker(vehiculo: Vehiculo) {
    this.markers[vehiculo.imei].isOpenInfoWindow = vehiculo.isOpenInfoWindow;
    this.vehicles = Object.values(this.markers);
  }


  cerrarSesion() {
    this.loginService.cerrarSesion(true);
  }

  getPermiso(id: string): Permiso {
    let permiso: PermisoClass;
    try {
      permiso = this.loginService.permisos.filter((p) => p.id === id)[0];
    } catch ( e ) {
      permiso = new PermisoClass();
    }

    return permiso;
  }

  getLink(path: string): string {
    return `${environment.endPoint}${path}?${this.loginService.wpsid.getUrlEncode()}`;
  }

  getUserName() {
    return this.loginService.usrName;
  }

  /**
   * Abre la ventana PopUp DetalleMapaComponent
   *
   * @param number imei
   */
  openPopUp(imei: number) {
    const config = new MatDialogConfig();
    config.autoFocus = true;
    this.inputDetalleMapa = new InputDetalleMapa();

    //  busca el registroHc del cliente seleccionado ó de la flota
    this.inputDetalleMapa.data = this.markers[imei];
    this.inputDetalleMapa.isDireccion = this.mapOptions.showDirections;


    config.data = this.inputDetalleMapa;
    console.log('Flota Completa?');
    this.dialog.open(DetalleMapaComponent, config);


    this.dialog.afterAllClosed.subscribe(_ => {
      this.inputDetalleMapa = null;
    });
  }

  getTooltipMarker(regHc: RegistroHC, meta: Meta): string {
    return `${regHc[meta.patente]}   Velocidad     ${regHc[meta.sog]}`;    
  }

  /**
   * Devuelve la cadena de dirección.
   * En el hemisferio sur (latitud negativa) es:
   *  Nombre calle, número y ciudad
   * En el hemisferio norte (latitud positiva) es:
   *  Nombre calle, número, colonia, delegación, código postal, ciudad, país
   *
   * @param point
   * @param geoResponse
   */
  async _parsearDireccion(point: Point, geoResponse: any) {
    const coalesce = (text) => text === undefined ? '' : text;
    const {road, house_number, city, county, state, postcode, country} = geoResponse.address;
    if ( house_number == null || house_number.length === 0 || house_number === '' ) {
      // no address
    }
    if ( point.lat > 0 ) {
      return `${ coalesce(road) } ${ coalesce(house_number) } ${ coalesce(city) } ${ coalesce(county) } ${ coalesce(state) } ${ coalesce(postcode) } ${ coalesce(country) }`;
    }

    return `${ coalesce(road) } ${ coalesce(house_number) } ${ coalesce(city) }`;
  }

  /**
   * Toggle Marker Cluster
   *
   * @param boolean enableCluster
   */
  toggleMapOptions(mapOptions: MapOptions) {
    this.mapOptions = new MapOptions(mapOptions);
  }

  /**
   *   get a new instancia of  google.maps.LatLng
   *
   * @param (number |  string) lat
   * @param (number |   string) lng
   * @returns google.maps.LatLng
   */
  getGoogleLatLng(agmMarker: AgmMarker): google.maps.LatLng {
    return new google.maps.LatLng(Number(agmMarker.latitude), Number(agmMarker.longitude));
  }


  updateSelectedVehicles(showList: any[]) {
    this.showList = showList.length === 0 ? [ 1 ] : showList;
    this.setMarkersFlotaCompleta();
  }

  customFilterPredicate() {
    return (data: any, filter: string): boolean => {
      const searchString = JSON.parse(filter) as VehiculosFilter;
      let isPatenteAvailable = false;

      if ( searchString.patente.length ) {
        for ( const d of searchString.patente ) {
          if ( data.patente.toString().trim() === d ) {
            isPatenteAvailable = true;
          }
        }
      } else {
        isPatenteAvailable = true;
      }

      let isGrupoAvailable = false;

      if ( searchString.nombreGrupo.length ) {
        for ( const d of searchString.nombreGrupo ) {
          if ( data.nombreGrupo.toString().trim() === d ) {
            isGrupoAvailable = true;
          }
        }
      } else {
        isGrupoAvailable = true;
      }

      let isFlotaAvailable = false;

      if ( searchString.nombreFlota.length ) {
        for ( const d of searchString.nombreFlota ) {
          if ( data.nombreFlota.toString().trim() === d ) {
            isFlotaAvailable = true;
          }
        }
      } else {
        isFlotaAvailable = true;
      }

      return isPatenteAvailable && isGrupoAvailable && isFlotaAvailable;
    };
  }

  putPosition({point, address}) {
    this.positionSetted = {
      ...point,
      address,
      icon: {
        url: `./pin.svg`,
        scaledSize: {
          width: 40,
          height: 40
        }
      }
    };
  }

  /**
   * Actualiza la direccion de los vehículos filtrados en la tabla
   *
   */
  async cargarDirecciones() {
    for ( let i = this.startIndex; i < this.endIndex; i++ ) {
      const el = this.dataSource.filteredData[i];
      if ( el === undefined ) {
        continue;
      }

      if ( el.lastPoint === undefined || el.lastPoint.lat !== el.point.lat || el.lastPoint.direccion === '---' ) {
        const point = new Point(el.point.lat, el.point.lng);
        this.loginService.getDireccion(point).subscribe(async (response) => {
          el.point.direccion = await this._parsearDireccion(point, response);
        });
      } else {
        el.point.direccion = el.lastPoint.direccion;
      }
    }
  }

}
