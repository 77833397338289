import { Indicador } from './indicador';

export class Rendimiento extends Indicador {

  lastWeek: any = null;
  beforeLastWeek: any = null;

  constructor(data?: any | Rendimiento) {
    super();
    if (data !== undefined) {
      Object.assign(this, data);
    }
  }
}
