// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//import 'zone.js/dist/zone-error';
import { version } from '../../package.json';

export const environment = {
  production: false,
  apiKeyAut: 'AIzaSyBRMSHbh8aPwDbbWzxwjS0pl0nSfQtlrEk',
  apiEndPoint: 'https://api-dev.waypoint.cl/indicadores',
  endPoint: window.location.hostname.endsWith('tranciti.com')?'https://app-dev.tranciti.com' :'https://id2.waypoint.cl',
  endPointApiInstantanea: 'https://rt-dev.waypoint.cl/realtimedata/latest',
  endPointApiWebsocket: 'wss://rt-dev.waypoint.cl/realtimedata',
  endpointPermisos: 'https://api-dev.waypoint.cl/permissions-service/getPermisos',
  endpointMenu: 'https://api-dev.waypoint.cl/menu-service/getMenu',
  endPointApiAlert: 'https://api-dev.waypoint.cl/lastmile',
  endpointValidacionCaptcha: 'https://mtvjrgeiib.execute-api.us-west-1.amazonaws.com/prod/captcha',
  endPointApiVehicleOnRoute: 'https://api-dev.waypoint.cl/lastmile/route/checkVehicleStatusOnRoute',
  appLogin: 'https://app-dev.tranciti.com/login/#',
  locationIQ: {
    endPointReverseGeocoding: 'https://us1.locationiq.com/v1/reverse.php',
    keydashboard: 'pk.7c9862d2c626d7fc987279c8110826b3'
  },
  google: {
    siteKey: '6LeA2hEaAAAAABiZm9rSPYoUcdX4F00DcR3yGs2O',
    cantidadIntentos : '3'
  },
  rbacEndpoint: "https://auth-dev.waypoint.cl/rbac",
  coreEndpoint: "https://api-dev.waypoint.cl/core",
  appVersion: version + '-DEV'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
