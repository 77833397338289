import { Component, Input, OnInit } from '@angular/core';
import { HistoricData } from 'app/_class/historicData/historic-data';

@Component({
  selector: 'app-detalle-historial-ruta',
  templateUrl: './detalle-historial-ruta.component.html',
  styleUrls: ['./detalle-historial-ruta.component.css']
})
export class DetalleHistorialRutaComponent implements OnInit {


  @Input() set setData(data) {
    if (!data) {
      return;
    }
    this.historicData = data;
    this.coveredDistance = data.vdistance.split('.')[0] + ' kms';
    this.maxSpeed = data.vvmax;
    this.averageSpeed = data.vvavg;
    try {
      this.startTime = new Date(data.startMarker.epoch).toISOString();
      this.endTime = new Date(data.endMarker.epoch).toISOString();
    } catch (e) {
      this.startTime = '';
      this.endTime = '';
    }
  }

  historicData: HistoricData;
  startTime = '';
  endTime = '';
  startPlace = '';
  endPlace = '';
  coveredDistance = '0 kms.';
  averageSpeed = '0 km/h';
  maxSpeed = '0 km/h';
  registers = '';

  constructor() { }

  ngOnInit() {
  }

}
