<div class="container relative" fxLayout="row" fxLayoutAlign="space-between start">
  <div class="secundario">
    <div class="graficos-dashboard">
      <mat-card class="example-card no-border">
        <mat-card-content>
          <div class="ajust-space">
            <div class="mod-graf">
              <div class="head-graf">
                <span>Flota Activa</span>
                <span
                mat-icon-button
                matTooltip="{{this.flotaActivaTooltip}}"
                matTooltipClass="custom-tooltip"
                class="icon-tooltip">
                  <mat-icon>info</mat-icon>
                </span>
                <mat-progress-bar *ngIf="!iFlota.isLoad" mode="indeterminate"></mat-progress-bar>
              </div>
              <div class="body-graf">
                <span class="number-graf">
                  <em class="material-icons">
                    directions_car
                  </em>
                  <span *ngIf="iFlota.isLoad">
                    {{iFlota.activos}}/{{iFlota.total}}
                  </span>
                  <span *ngIf="!iFlota.isLoad">
                    {{iStatusNull}}
                  </span>
                </span>
                <span class="number-graf-2">
                  <em class="material-icons ">
                    show_chart
                  </em>
                  <span *ngIf="iFlota.isLoad">
                    {{iFlota.activos*100/iFlota.total|number: '2.0-0'}}%
                  </span>
                  <span *ngIf="!iFlota.isLoad">
                    {{iStatusNull}}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="ajust-space">
            <div class="mod-graf">
              <div class="head-graf" >
                <a class="head-graf" (click)="goAlertas()">Alertas del día</a>
                <span
                  mat-icon-button
                  matTooltip="{{this.alertasDelDiaTooltip}}"
                  matTooltipClass="custom-tooltip"
                  class="icon-tooltip">
                  <mat-icon>info</mat-icon>
                </span>
                <mat-progress-bar *ngIf="!iAlerta.isLoad" mode="indeterminate"></mat-progress-bar>
              </div>
              <div class="body-graf">
                <span class="number-graf-1">
                  <em class="material-icons ">
                    notification_important
                  </em>
                  <span *ngIf="iAlerta.isLoad">
                    {{iAlerta.total}}
                  </span>
                  <span *ngIf="!iAlerta.isLoad">
                    {{iStatusNull}}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="ajust-space">
            <div class="mod-graf">
              <div class="head-graf">
                <span>Moviles con Actividad</span>
                <span
                  mat-icon-button
                  matTooltip="{{this.movilesActividadTooltip}}"
                  matTooltipClass="custom-tooltip"
                  class="icon-tooltip">
                  <mat-icon>info</mat-icon>
                </span>
                <mat-progress-bar *ngIf="!iActividad.isLoad" mode="indeterminate"></mat-progress-bar>
              </div>
              <div class="body-graf">
                <span class="number-graf-2">
                  <span *ngIf="iActividad.isLoad">
                    {{iActividad.lastWeek.activos}}/{{iActividad.lastWeek.total}}
                  </span>
                  <span *ngIf="!iActividad.isLoad">
                    {{iStatusNull}}
                  </span>
                </span>
                <span class="number-graf-3">Semana Pasada</span>
                <span
                  class="number-graf-4">{{iActividad.beforeLastWeek.activos}}/{{iActividad.beforeLastWeek.total}}</span>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
