
<div class="containerx">
  <div class="titleContainerx">
    <mat-card-title class="titlex">Editar Mi Perfil</mat-card-title>
    <button mat-button class="close" (click)="closeModal()">&#10005;</button>
  </div>

  <mat-divider></mat-divider>
  <div>
    <form (ngSubmit)="onSubmit()">
      <div [formGroup]="form" class="containerX">
        <mat-grid-list cols="2" rowHeight="2:0.5">
          <mat-grid-tile class="containerInput">
            <mat-form-field class="inputWidth">
              <label>Usuario:</label>
              <input matInput formControlName="usuario" id="usuario" name="usuario"
                     readonly>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile class="containerInput">
            <mat-form-field class="inputWidth">
              <label>Nombre:</label>
              <input matInput name="nombre" formControlName="nombre">
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile class="containerInput">
            <mat-form-field class="inputWidth">
              <label>Apellido:</label>
              <input matInput class="form-control" id="apellido" formControlName="apellido"
                     name="apellido" value="{{data.apellido}}">
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile class="containerInput">
            <mat-form-field class="inputWidth">
              <label>Email:</label>
              <input matInput id="correo" formControlName="correo" name="correo"
                     value="{{data.correo}}">
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile class="containerInput">
            <mat-form-field class="inputWidth">
              <label>Teléfono:</label>
              <input matInput class="form-control" formControlName="telefono"
                     name="telefono" value="{{data.telefono}}" maxlength="9">
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile class="containerInput as">
            <mat-radio-group formControlName="sexo" name="sexo">
              <label>Sexo:</label><br>
              <mat-radio-button class="spacing" value="h">Hombre</mat-radio-button>
              <mat-radio-button class="spacing2" value="m">Mujer</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group formControlName="idioma" name="idioma">
              <label class="spacing2">Idioma:</label><br>
              <mat-radio-button class="spacing" value="ESP">Español</mat-radio-button>
              <mat-radio-button class="spacing2" value="ING">Inglés</mat-radio-button>
            </mat-radio-group>
          </mat-grid-tile>

          <mat-grid-tile class="containerInput">
            <mat-form-field class="inputWidth">
              <label>Dirección:</label>
              <input matInput formControlName="direccion" name="direccion" readonly>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile class="containerInput">
            <mat-form-field class="inputWidth">
              <label>Comuna/Ciudad:</label>
              <input matInput formControlName="ciudad" name="ciudad" readonly>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile class="containerInput">
            <mat-form-field class="inputWidth">
              <label>Cargo:</label>
              <input matInput formControlName="cargo" name="cargo" readonly>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile class="containerInput">
            <mat-form-field class="inputWidth">
              <label>Fecha Nacimiento:</label>
              <input matInput [matDatepicker]="picker" formControlName="nacimiento" name="nacimiento" readonly>
              <mat-datepicker-toggle matSuffix [for]="picker" disabled></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </mat-grid-tile>

        </mat-grid-list>

        <!-- Subir imagen-->
        <mat-label for="singleFile">Cargar fotografía perfil (opcional)</mat-label>
        <br>
        <input id="singleFile" type="file" formControlName="file" [fileUploadInputFor]="fileUploadQueue"  disabled/>
        <mat-file-upload-queue #fileUploadQueue
                               [fileAlias]="'file'"
                               [httpUrl]="'http://localhost:8180/jax-rs-jersey-application-sample'" readonly>

          <mat-file-upload [file]="file" [id]="i"
                           *ngFor="let file of fileUploadQueue.files; let i = index"></mat-file-upload>
        </mat-file-upload-queue>

        <mat-grid-list cols="3" rowHeight="2:1.6">
          <h3>Cambiar Contraseña</h3>
          <mat-grid-tile class="containerInput">
            <mat-form-field class="inputWidth">
              <label>Contraseña:</label>
              <input matInput [type]="hide ? 'password' : 'text'" formControlName="oldPassword" name="pass">
              <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile class="containerInput">
            <mat-form-field class="inputWidth">
              <label>Nueva Contraseña:</label>
              <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" name="pass1">
              <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error>
                <p>-Al menos 8 caracteres de longitud</p>
                <p>-Letras minusculas</p>
                <p>-Letras mayúsculas</p>
                <p> -Números</p>
                <p>-Caracteres especiales</p>
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile class="containerInput">
            <mat-form-field class="inputWidth">
              <label>Repetir Nueva Contraseña:</label>
              <input matInput [type]="hide ? 'password' : 'text'" name="pass2"
                     formControlName="confirmPassword">
              <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>

              <mat-error
                *ngIf="form.controls['confirmPassword'].pristine || form.controls['confirmPassword'].errors?.MatchPassword">
               <p>Las contraseñas no son iguales</p>
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>

        <mat-divider></mat-divider>
        <div class="btn">
          <button class="btnCancel" mat-raised-button color="warn" (click)="closeModal()">Cancelar</button>
          <button class="btnSave" mat-raised-button color="primary" [disabled]="disabled()">Guardar Cambios</button>
        </div>
      </div>
    </form>
  </div>
</div>

