import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from '../login.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermisosPerfilesService {
  private readonly sessions = `${environment.endPoint}/WaypointSessions`;
  private readonly intro = `${this.sessions}/introspect`;
  private readonly validar = `${this.sessions}/cognito/validar`;
  private intervalID: number;
  private readonly diezMinutos = 600000;
  private perfilpermisos;
  private scope;

  constructor(
    private readonly cs: CookieService,
    private readonly ls: LoginService,
    private readonly http: HttpClient) {
    this.intervalID = null;
  }

  mointorear() {
   if (!this.intervalID) {
      this.intervalID = window.setInterval(
        () => {
            this.validarToken();
        }, this.diezMinutos);
        this.validarToken();
    }
  }
  /** solo es válido cuando el intervalo del monitoreo es usado */
  private validarToken() {
    if (this.intervalID) {

      const token = this.cs.get('at') || localStorage.getItem('at');

      const payload = new HttpParams()
        .set('token', token);
      const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      this.http.post(this.validar, payload, { headers, observe: 'response' }).pipe(
        catchError(error => {
          return of(error);
        })).subscribe(res => {
          if (res.status !== 204) {
            window.clearInterval(this.intervalID);
            this.intervalID = null;
            alert('Error: sesión expirada 2');
            this.ls.cerrarSesion(true);
          }
        });
    }
  }

  /** obtiene los perfiles y permisos para permitir a la vista filtrar elementos. */
  introspect(token: string) {
    const http = new XMLHttpRequest();
    const params = `token=${token}`;
    http.open('POST', this.intro, false);
    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    http.send(params);
    let intro = null;
    if (http.status === 200) {
      intro = http.responseText;
    }
    return intro;
  }

  tieneAcceso(acceso: string): boolean {
    let existe = true;
    try {
      if ((typeof this.perfilpermisos === 'undefined' || this.perfilpermisos === null) && typeof this.scope === 'undefined') {
        this.perfilpermisos = JSON.parse(this.introspect(this.cs.get('at')));
        this.scope = this.perfilpermisos.scope;
      }
      if (this.perfilpermisos && this.perfilpermisos.scope) {
        existe = this.scope.includes(acceso);
      }
    } catch (e) {
      this.scope = null;
    }
    return existe;
  }
}
