import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-configuracion-usuario-dialog',
  templateUrl: './configuracion-usuario-dialog.component.html',
  styleUrls: ['./configuracion-usuario-dialog.component.css']
})
export class ConfiguracionUsuarioDialogComponent implements OnInit {
  form: FormGroup;
  hide = true;
  rowHeigth = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public modal: MatDialogRef<ConfiguracionUsuarioDialogComponent>, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.rowHeigth = '2:1'
    //Comprueba la contraseñas
    console.log(this.data)
    this.form = this.formBuilder.group({
      usuario:[this.data.usuario],
      nombre:[this.data.nombre],
      apellido:[this.data.apellido],
      correo:[this.data.correo],
      telefono:[this.data.telefono],
      sexo:[this.data.sexo],
      idioma:[this.data.idioma],
      direccion:[this.data.direccion],
      ciudad:[this.data.ciudad],
      cargo:[this.data.cargo],
      nacimiento:[this.data.nacimiento],
      file:[this.data.file],
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      confirmPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      oldPassword: ['', Validators.required]
    }, {
      validator: this.MatchPassword
    });
  }

  //Comprueba pass sean iguales
  MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value;
    if (AC.get('confirmPassword').touched || AC.get('confirmPassword').dirty) {
      const verifyPassword = AC.get('confirmPassword').value;

      if (password !== verifyPassword) {
        AC.get('confirmPassword').setErrors({MatchPassword: true});
      } else {
        return null;
      }
    }
  }

  //Desabilita boton de envio hasta cumplir las condiciones del pass
  disabled() {
    const {confirmPassword, oldPassword, password} = this.form.value;
    if (password !== '' || oldPassword !== '' || confirmPassword !== '') {
      return this.form.status === 'INVALID';
    } else {
      return false;
    }
  }

  //Cerrar modal
  closeModal() {
    this.modal.close(null);
  }

  //Envio de formulario
  onSubmit() {
    let dataUser: any;
    this.data.nacimiento = moment(this.data.nacimiento).format('YYYY-MM-DD');
    const {confirmPassword, oldPassword, password, } = this.form.value;
    this.modal.close(dataUser = {
      'sexo': this.form.value.sexo,
      'idioma': this.form.value.idioma,
      'apellido': this.form.value.apellido,
      'user': this.form.value.usuario,
      'userid': this.data.id,
      'pass': this.form.status === 'VALID' ? password : '',
      'oldpass': this.form.status === 'VALID' ? oldPassword : '',
      'nombre': this.form.value.nombre,
      'correo': this.form.value.correo,
      'telefono': this.form.value.telefono,
      'cambiopass': this.form.status === 'VALID'
    });


  }

}
