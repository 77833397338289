import { Indicador } from './indicador';
import { FlotaActiva } from './flota-activa';

export class Actividad  extends Indicador{
lastWeek = new  FlotaActiva();
beforeLastWeek = new  FlotaActiva();

constructor(data?: any | Actividad) {
  super();
  if (data !== undefined) {
    Object.assign(this, data);
  }
}
}
