import { Injectable } from '@angular/core';
import { WPSID } from 'app/_class/wpsid';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Vehiculo } from 'app/_class/vehiculo';
import { Alert } from "../../_interfaces/alerts";

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {

  wpsid: WPSID = new WPSID('');

  constructor(private http: HttpClient) { }

  async getAlert(request: AlertRequest): Promise<Alert[]> {
    const chunkSize = 30; // Número de IDs por cada solicitud GET
    const idVehiclesFilterChunks = [];

    // Divide idVehiclesFilter en subgrupos de tamaño "chunkSize"
    for (let i = 0; i < request.idVehiclesFilter.length; i += chunkSize) {
      idVehiclesFilterChunks.push(request.idVehiclesFilter.slice(i, i + chunkSize));
    }

    const promises: Promise<any>[] = [];

    const commonParams = new HttpParams()
      .set('startDateFilter', request.startDateFilter.toString())
      .set('endDateFilter', request.endDateFilter.toString());

    const headers = new HttpHeaders({
      'id-client': request.idClient.toString()
    });

    const url = `${environment.endPointApiAlert}/alert/vehicle`;

    // Crea una promesa para cada subgrupo de IDs
    idVehiclesFilterChunks.forEach(chunk => {
      let params = commonParams;
      chunk.forEach(id => {
        params = params.append('idVehiclesFilter', id.toString());
      });
      const promise = this.http.get<any>(url, { params, headers }).toPromise();
      promises.push(promise);
    });

    try {
      const responses = await Promise.all(promises);
      // Combina los resultados en un solo array
      return [].concat(...responses.map(response => response.data));
    } catch (error) {
      console.error('Error en la petición:', error);
      throw error;
    }
  }

  isVehicleOnRoute(id: number) {
    const url = environment.endPointApiVehicleOnRoute + `?idVehicle=${id}`;
    return this.http.get(url);
  }

  async getVehiclesList(idCliente: number) {
    const url = `${ environment.endPoint }/Req/GetJsonHC?idcliente=${idCliente}&${this.wpsid.getUrlEncode()}&st=true`;
    let vehicles: Vehiculo[] = [];

    await this.http.get<Vehiculo[]>(url)
    .toPromise()
    .then((data: any) => {
        const vehs = data && data.contenido && data.contenido.registro ? data.contenido.registro : [];
        vehicles = vehs.map((v: any) => {
          const veh =  new Vehiculo();
          veh.setData(v);
          return veh;
        });
    });

    return vehicles;
  }

  // convertir metodo a async

  /*async getVehicleByIdVehicle(idCliente: number) {
    const url1 = `${ environment.endPoint }/Req/GetJsonHC?idcliente=${ idCliente }&${ this.wpsid.getUrlEncode() }&st=true`;
    let vehicles: Vehiculo2[] = [];
    let datareturned: JsonHc = await this.http.get<JsonHc>(url1);
    const vehicleIds: number[] = datareturned.contenido.registro.map(registro => registro.W1);
    const url2 = `${ environment.endPointApiInstantanea }`;
    await this.http.post(url2, { vehicleIds}).toPromise().then(
          (data: any) => {
            //console.log(`VEHICLES (${url}):`, data);
            const vehs = data && data.contenido && data.contenido.registro ? data.contenido.registro : [];
            vehicles = vehs.map((v: any) => {
              const veh =  new Vehiculo();
              veh.setData(v);
              return veh;
            });
        }
        );
  }*/

}
