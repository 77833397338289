export class Indicador {
  isLoad = false;
  status = '';

  public setLoadOff(): void {
    this.isLoad = false;
  }

  public setLoadOn(): void {
    this.isLoad = true;
  }

  public isComplete(): boolean {
    return this.status === 'COMPLETADO';
  }

}
