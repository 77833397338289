import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import * as Cookies from 'js-cookie';
@Injectable({
  providedIn: 'root'
})
export class GrantsByClientsService {

  private urlService = environment.endpointMenu;

  constructor(private http: HttpClient) { }

  async getMenu(client: number) {

    if (client === -1 ) {
      return;
    }

    const httpOptions = {
      headers: new HttpHeaders().set(
        'Content-Type', 'application/json').set('Access-Control-Allow-Origin', '*')
    };

    const at = Cookies.get('at') || localStorage.getItem('at');
    const wpsid = Cookies.get('wpsid') || localStorage.getItem('wpsid');
    console.log({at, wpsid});

    let list = null;
    await this.http.post(this.urlService, { wpsid, at, client }, httpOptions)
      .toPromise()
      .then((data: any) => {
        console.log('la data', data);
        const {res} = data;
        list = res;
      }).catch(err => console.log);

    return list ? list : [];
  }
}

