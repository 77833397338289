import { Injectable } from '@angular/core';

declare var WaypointUtils: any;

/**
 * métodos utiles para tratar con mapas y direcciones.
 */
@Injectable({
  providedIn: 'root'
})
export class MapsUtilService {
  private readonly MAPS_KEY = 'pk.c68558c6a79d105e6c857c465dc2fda7';

  constructor() { }

  addressFromPoint(lat: number, lng: number, callback: any) {
    if (lat && lng) {
      try {
        WaypointUtils.addressFromPoint({
          lat: lat, lon: lng,
          key: this.MAPS_KEY,
          cb: (e: any) => callback(e)
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
}
