import { Options } from './../../_class/Options/options';
import { GroupsService } from './../../_services/groups/groups.service';
import { FleetsService } from './../../_services/fleets/fleets.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ClientsService } from 'app/_services/clients/clients.service';
import { VehiclesService } from 'app/_services/vehicles/vehicles.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;
const formatFull = 'DD-MM-YYYY';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: formatFull,
  },
  display: {
    dateInput: formatFull,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: formatFull,
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-opciones-historial-ruta',
  templateUrl: './opciones-historial-ruta.component.html',
  styleUrls: ['./opciones-historial-ruta.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class OpcionesHistorialRutaComponent implements OnInit {

  datePickerFrom = new FormControl(moment().format(formatFull));
  datePickerTo = new FormControl(moment().format(formatFull));

  datePickerFromSettings = {
    type: 'daily',
    timePicker: true,
    inputDateFormat: formatFull,
    viewDateFormat: formatFull,
    outputDateFormat: 'YYYY-MM-DD HH:mm',
  };

  options = new Options();

  @Output() selectedOptions  = new EventEmitter<Options>();

  constructor(
    private readonly clientsService: ClientsService,
    private readonly groupsService: GroupsService,
    private readonly fleetService: FleetsService,
    private readonly vehiclesService: VehiclesService
  ) { }

  ngOnInit() {
    this.loadClients();

  }

  loadVehicles(client: number, group = 0, fleet = 0) {
    this.vehiclesService.getVehiclesList(client).then((res: any[]) => {
      res = group === 0 ? res : res.filter(({ idGrupo }) => idGrupo === group);
      res = fleet === 0 ? res : res.filter(({ idClase }) => idClase === fleet);
      this.options.vehicles = res.map(({ imei, patente, descripcion }) => ({ id: imei, descripcion: `${patente} ${descripcion}` }));
    });
  }

  loadFleets(client: number) {
    this.fleetService.getFleets(client).then((res: any[]) => {
      this.options.fleets = res.map(({ id, name }) => ({ id, descripcion: name }));
    });
  }

  loadGroups(client: number) {
    this.groupsService.getGrupos(client).then((res: any[]) => {
      this.options.groups = res.map(({ id, nombre }) => ({ id, descripcion: nombre }));
    });
  }

  loadClients() {
    this.clientsService.getClients().subscribe((res: any[]) => {
      this.options.clients = res.map(({ id, name }) => ({ id, descripcion: name }));
    });
  }

  setSelect(event: any, type: string) {
    console.log(type, event);
    switch (type) {
      case 'client':
        this.options.clientSelected = event;
        this.options.groupSelected = 0;
        this.options.fleetSelected = 0;
        this.loadGroups(this.options.clientSelected);
        this.loadFleets(this.options.clientSelected);
        this.loadVehicles(this.options.clientSelected);
        break;
      case 'group':
        this.options.groupSelected = event;
        this.loadVehicles(this.options.clientSelected, this.options.groupSelected, this.options.fleetSelected);
        break;
      case 'fleet':
        this.options.fleetSelected = event;
        this.loadVehicles(this.options.clientSelected, this.options.groupSelected, this.options.fleetSelected);
        break;
      case 'vehicle':
        this.options.vehicleSelected = event;
        break;
      case 'stopTime':
        this.options.stopTimeSelected = event;
        break;
      case 'speed':
        this.options.speedSelected = event;
        break;
      case 'precision':
        const splitted = event.split(',');
        this.options.precision = parseFloat(splitted[0]);
        break;
      default:
        console.log('sin seleccion');
        break;
    }
  }

  doFilter() {
    if (this.options.vehicleSelected === 0) {
      return false;
    }

    console.log('clickk para hacer el historial');
    this.selectedOptions.emit(this.options);
  }
}

