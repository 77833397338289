export class MapOptions {
  typeChanged = '';
  automaticFit = false;
  showZones = false;
  showTraffic = false;
  showDirections = true;
  showPoints = false;
  showLabels = false;
  showCluster = true;
  showCog = false;
  onRoute = false;

  constructor(m: MapOptions = null) {
    if ( m ) {
      this.typeChanged = m.typeChanged;
      this.automaticFit = m.automaticFit;
      this.showZones = m.showZones;
      this.showTraffic = m.showTraffic;
      this.showDirections = m.showDirections;
      this.showPoints = m.showPoints;
      this.showLabels = m.showLabels;
      this.showCluster = m.showCluster;
      this.showCog = m.showCog;
      this.onRoute = m.onRoute;
    }
  }

  toggle(value: boolean, type: string): void {
    switch ( type ) {
      case 'fit':
        this.automaticFit = value;
        break;
      case 'zones':
        this.showZones = value;
        break;
      case 'traffic':
        this.showTraffic = value;
        break;
      case 'directions':
        this.showDirections = value;
        break;
      case 'points':
        this.showPoints = value;
        break;
      case 'labels':
        this.showLabels = value;
        break;
      case 'cluster':
        this.showCluster = value;
        break;
      case 'onRoute':
        this.onRoute = value;
        break;
      case 'cog':
        this.showCog = value;
        break;
    }
  }
}
