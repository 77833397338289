import { Component, OnInit } from '@angular/core';
import { GetHistorialService } from '../../../../app/_services/historial/get-historial.service';
import { ActivatedRoute } from '@angular/router';
import { FechaUtilService } from '../../../../app/_utils/fecha-util/fecha-util.service';
import { MapsUtilService } from '../../../../app/_services/maps-util/maps-util.service';

@Component({
  selector: 'app-historial-ruta',
  templateUrl: './historial-ruta.component.html',
  styleUrls: ['./historial-ruta.component.css']
})
export class HistorialRutaComponent implements OnInit {

  datos: any;
  imei: any;
  /** fecha de inicio del trayecto */
  fechaInicio = '';
  /** fecha de termino del trayecto */
  fechaTermino = '';
  /** tiempo transcurrido en el traslado */
  ptime = '';
  /** velocidad promedio */
  vvavg = '';
  /** velocidad máxima */
  vvmax = '';
  /** distancia recorrida */
  vdistance = '';
  /** lugar de inicio */
  lugarInicio = '';
  /** lugar de termino */
  lugarTermino = '';
  /** tiempo detenido */
  tiempoDetenido = '';

  // mapa
  public marcadores = [];
  public trazarRuta = false;
  dibujarTraza = false;
  public trayectos = [];
  startMarker: any;
  endMarker: any;
  stopMarkers = [];
  routePoints = [];
  conDatos = true;
  backFlotaCompleta = false;
  back = '/dashboard';

  getEstimacionesRuta(geoevent: any) {
    this.trayectos = geoevent.geoevent.routes[0].legs.map(leg => ({
      distance: leg.distance,
      duration: leg.duration,
      startLocation: leg.start_location,
      startAddress: leg.start_address,
      endLocation: leg.end_location,
      endAddress: leg.end_address
    }));
  }

  constructor(
    private readonly router: ActivatedRoute,
    private readonly gh: GetHistorialService,
    private readonly fus: FechaUtilService,
    private readonly mus: MapsUtilService) {
    this.imei = this.router.snapshot.queryParamMap.get('imei');
    const back = this.router.snapshot.queryParamMap.get('backCompleta');
  }

  ngOnInit() {
    this.gh.ultimas48horas(this.imei).subscribe((data: any) => {
      if (data) {
        this.datos = data;
        this.mostrarDatos();
      } else {
        alert('Sin Datos para Mostrar');
      }
    });
  }

  mostrarDatos() {
    this.fechaInicio = this.fus.fechaDdMmYyyyMmSs(new Date(this.datos.pstart.epoch * 1000));
    this.fechaTermino = this.fus.fechaDdMmYyyyMmSs(new Date(this.datos.pend.epoch * 1000));
    this.ptime = this.datos.ptime ? `${this.fus.hhmmss(this.datos.ptime)}` : '';
    this.vvavg = this.datos.vvavg ?  this.datos.vvavg : '0';
    this.vvmax = this.datos.vvmax ?  this.datos.vvmax : '0';
    this.vdistance = this.obtenerDistanciaRecorrida(this.datos.vdistance);
    this.mus.addressFromPoint(this.datos.pstart.lat, this.datos.pstart.lon, (e:any) => this.lugarInicio = e);
    this.mus.addressFromPoint(this.datos.pend.lat, this.datos.pend.lon, (e:any) => this.lugarTermino = e);
    this.startMarker = { punto: this.datos.pstart};
    this.endMarker = this.datos.pend;
    this.stopMarkers = this.datos.stop;
    this.routePoints = this.datos.data.map(item => {
      return {
        ...item,
        lng: item.lon
      };
    });
    this.dibujarTraza = true;
    this.calculatTiempoDetenido();
    if(!this.routePoints || (this.routePoints && this.routePoints.length === 0)) {
      this.conDatos = false;
    }
  }
  /** suma la propiedad tiempo de datos.stop (segundos). */
  calculatTiempoDetenido() {
    if (this.datos && this.datos.stop) {
      let suma = 0;
      for (const st of this.datos.stop) {
        suma += st.tiempo;
      }
      this.tiempoDetenido = this.fus.hhmmss(suma);
    } else {
      this.tiempoDetenido = '';
    }
  }

  /**
   * obtiene el string Xkm Ymt.
   *
   * @param dato metros recorridos
   */
  obtenerDistanciaRecorrida(dato) : string {
    let ret = '0km 0mt';
    if (dato) {
      const kmTotales = dato / 1000;
      const kmEnteros = kmTotales | 0;
      const metrosTotales = ((kmTotales - kmEnteros) * 1000) | 0;
      ret = `${kmEnteros}km ${metrosTotales}mt`;
    }
    return ret;
  }
}
