import { MapsUtilService } from 'app/_services/maps-util/maps-util.service';
import { FechaUtilService } from 'app/_utils/fecha-util/fecha-util.service';

export class HistoricData {
    ptime: string;
    vvavg: number;
    vvmax: number;
    vdistance: string;
    startMarker: { punto: any; };
    endMarker: any;
    stopMarkers: any;
    routePoints: any;
    dibujarTraza: boolean;
    tiempoDetenido: any;
    fus: FechaUtilService;
    mus: MapsUtilService;
    lugarInicio: any;
    lugarTermino: any;

    constructor(data: any, fus: FechaUtilService, mus: MapsUtilService) {
        this.ptime = data.ptime ? `${fus.hhmmss(data.ptime)}` : '';
        this.vvavg = data.vvavg ? data.vvavg : '0';
        this.vvmax = data.vvmax ? data.vvmax : '0';
        this.vdistance = this.obtenerDistanciaRecorrida(data.vdistance);
        this.fus = fus;
        this.mus = mus;
        this.mus.addressFromPoint(data.pstart.lat, data.pstart.lon, (e: any) => this.lugarInicio = e);
        this.mus.addressFromPoint(data.pend.lat, data.pend.lon, (e: any) => this.lugarTermino = e);
        this.startMarker = { punto: data.pstart };
        this.endMarker = data.pend;
        this.stopMarkers = data.stop;
        this.routePoints = data.data.map((item: any) => {
            return {
                ...item,
                lng: item.lon
            };
        });
        this.dibujarTraza = true;
        this.calculatTiempoDetenido(data);
    }

    /** suma la propiedad tiempo de datos.stop (segundos). */
    calculatTiempoDetenido(data: any) {
        if (data && data.stop) {
            let suma = 0;
            for (const st of data.stop) {
                suma += st.tiempo;
            }
            this.tiempoDetenido = this.fus.hhmmss(suma);
        } else {
            this.tiempoDetenido = '';
        }
    }

    /**
     * obtiene el string Xkm Ymt.
     *
     * @param dato metros recorridos
     */
    obtenerDistanciaRecorrida(dato): string {
        let ret = '0km 0mt';
        if (dato) {
            const kmTotales = dato / 1000;
            const kmEnteros = kmTotales || 0;
            const metrosTotales = ((kmTotales - kmEnteros) * 1000) || 0;
            ret = `${kmEnteros}km ${metrosTotales}mt`;
        }
        return ret;
    }

}
