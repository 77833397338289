import { Injectable } from '@angular/core';

/**
 * métodos utiles para tratar con las fechas.
 */
@Injectable({
  providedIn: 'root'
})
export class FechaUtilService {

  /** obtiene la fecha en formato dd-mm-yyyy hh:mm:ss. */
  fechaDdMmYyyyMmSs(fx: Date): string {
    let res = `${this.slice(fx.getDate())}-${this.slice(fx.getMonth() + 1)}-${fx.getFullYear()} `;
    res = `${res}${this.slice(fx.getHours())}:${this.slice(fx.getMinutes())}:${this.slice(fx.getSeconds())}`;
    return res;
  }

  /** obtiene la fecha desde (hora - 48 horas). Formato: yyyy-mm-dd hh:mm:ss (24 horas). */
  formatoDesde(fx: Date) : string {
    fx.setHours(fx.getHours() - 48);
    return this.formatoHasta(fx);

  }

  /** obtiene la fecha hasta (ahora). Formato: yyyy-mm-dd hh:mm:ss (24 horas) */
  formatoHasta(fx: Date) : string {
    const ymd = this.fecha(fx);
    const hh = this.slice(fx.getHours());
    const mm = this.slice(fx.getMinutes());
    const ss = this.slice(fx.getSeconds());
    return `${ymd} ${hh}:${mm}:${ss}`;
  }

  /** obtiene un numero de 2 enteros agregandole un cero a la izquierda si es necesario. */
  slice(dato: number) {
    return (`0${dato}`).slice(-2);
  }

  /** obtiene yyyy-mm-dd */
  fecha(fx: Date) : string{
    return `${fx.getFullYear()}-${this.slice(fx.getMonth() + 1)}-${this.slice(fx.getDate())}`;
  }

  /**
   * obtiene hh:mm:ss desde los segundos recibidos.
   */
  hhmmss(segundos: number): string {
    const divisor = 60;
    const horasTotales = segundos / divisor / divisor;
    const horasEnteras = horasTotales | 0;
    const minutosTotales = (horasTotales - horasEnteras) * 60;
    const minutosEnteros = minutosTotales | 0;
    const segundosTotales = (minutosTotales - minutosEnteros) * 60;

    let res = `${this.slice(horasEnteras)}:${minutosEnteros >= divisor ? divisor - 1 : this.slice(minutosEnteros)}`;
    res = `${res}:${segundosTotales >= divisor ? divisor - 1 : this.slice(segundosTotales | 0)}`;
    return res;
  }
}
