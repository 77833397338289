import { Injectable } from '@angular/core';
import { IndexedDBAngular } from 'indexeddb-angular';
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private dbName = 'dashboardWpt';
  private dbVersion = 1;
  private store = 'clientList';

  private db = new IndexedDBAngular(this.dbName, this.dbVersion);
  constructor() { this.db.createStore(1, this.createCollections);}
  createCollections(db) {
      db.currentTarget.result.createObjectStore('clientList');
  }

  save(base: string, type: string, value: any) {
    try {
      const key = this.makeKey(base, type);
      if (type === 'clientList' || type === 'vehiclesToShow') {
        this.saveOnDB(key, type, value);
      } else {
        localStorage.setItem(key, JSON.stringify(value));
      }
    } catch (error) {
      console.error('Error al guardar:', error);
    }
  }

  async deleteFromDB(key: string){
    await this.db.delete(this.store, key);
  }

  async saveOnDB(key: string, type: string, value: any) {
    const newKey = key;
    try {
      await this.db.update(this.store, { time: new Date().getTime(), content: value }, newKey);
    } catch (error) {
      console.error('Error updating data', error);
    }
  }



  async getFromDB(base: string, type: string) {
    const key = this.makeKey(base, type);
    let result = { content: [] };
    await this.db.getByKey(this.store, key).then((data) => {
      result = data;
    }, (error) => {
      console.log(error);
    });

    return result;
  }

  get(base: string, type: string) {
    const key = this.makeKey(base, type);
    const data = localStorage.getItem(key);
    if (!data || data === undefined) {
      return null;
    }
    return JSON.parse(data);
  }

  deleteAllSelections( base: string) {
    const user = localStorage.getItem('id');
    const keyGroup = `${base}-${user}-group`;
    const keyFleet = `${base}-${user}-fleet`;
    const keyVehicle = `${base}-${user}-vehicle`;
    const keyGroups = `${base}-${user}-svdgrps`;
    localStorage.removeItem(keyGroup);
    localStorage.removeItem(keyFleet);
    localStorage.removeItem(keyVehicle);
    localStorage.removeItem(keyGroups);
  }

  deleteOne(base: string, type: string) {
    const key = this.makeKey(base, type);
    localStorage.removeItem(key);
  }

  makeKey(base: string, type: string) {
    const user = localStorage.getItem('id');
    return `${base}-${user}-${type}`;
  }
}
