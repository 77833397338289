<agm-map
  class="block-map"
  [zoom]="zoom"
  [latitude]="-33.4221357"
  [longitude]="-70.6038334"
  [fitBounds]="(this.marcadores.length > 0) || (this.puntosRuta.length > 0)|| (agmMarkers.length > 0) ? true : false"
  (mapReady)="mapReady($event)"
>
  <div *ngIf="agmMarkers">
    <agm-marker
      *ngFor="let i of agmMarkers"
      [agmFitBounds]="true"
      [latitude]="i.lat"
      [longitude]="i.lng"
      [iconUrl]="i.icn"
      [label]="i.label"
    >
    </agm-marker>
  </div>

  <agm-marker
    *ngIf="puntoOrigen && puntoOrigen.punto"
    [agmFitBounds]="true"
    [latitude]="puntoOrigen.punto.lat"
    [longitude]="puntoOrigen.punto.lon"
    [iconUrl]="{
      path: 'M0,10C0,4.5,4.5,0,10,0s10,4.5,10,10s-4.5,10-10,10S0,15.5,0,10z M10,16c3.3,0,6-2.7,6-6s-2.7-6-6-6s-6,2.7-6,6
      S6.7,16,10,16z',
      fillColor: 'orange',
      fillOpacity: 0.9,
      strokeWeight: 1,
      scale: 1.5
    }"
  >
  </agm-marker>

  <agm-marker
    *ngIf="puntoDestino && puntoDestino.lat"
    [agmFitBounds]="true"
    [latitude]="puntoDestino.lat"
    [longitude]="puntoDestino.lon"
    [iconUrl]="{
      path: 'M9.4,2L9,0H0v17h2v-7h5.6L8,12h7V2H9.4z',
      fillColor: 'green',
      fillOpacity: 0.9,
      strokeWeight: 1,
      scale: 1.5
    }"
  >
  </agm-marker>

  <div *ngIf="polyline" >
    <agm-polyline [strokeColor]="'#2196f3'" [strokeOpacity]="0.5">
      <agm-polyline-point
        *ngFor="let point of polyline"
        [latitude]="point.lat"
        [longitude]="point.lng"
      >
      </agm-polyline-point>
    </agm-polyline>
  </div>

  <div *ngIf="this.inicio && this.inicio.punto.lat != 0">
    <agm-marker
      *ngIf="this.inicio"
      [agmFitBounds]="true"
      [latitude]="this.inicio.punto.lat"
      [longitude]="this.inicio.punto.lng"
    >
    </agm-marker>
  </div>


  <div *ngFor="let marcador of puntosRuta; let i = index;">
      <agm-marker
        *ngIf="marcador && marcador.lat !== 0 && (i === 0 || i % 11 === 0)"
        [agmFitBounds]="true"
        [latitude]="marcador.lat"
        [longitude]="marcador.lng"
        [iconUrl]="{
          path: 'M9,16V3.8l5.6,5.6L16,8L8,0L0,8l1.4,1.4L7,3.8V16H9z',
          fillColor: '#091cab',
          fillOpacity: 0.7,
          strokeWeight: 1,
          scale: 0.6,
          rotation: marcador.cog
          }"
      >
      </agm-marker>
    </div>

  <div *ngFor="let marcador of marcadores">
    <agm-marker
      *ngIf="marcador && marcador.lat !== 0"
      [agmFitBounds]="true"
      [latitude]="marcador.lat"
      [longitude]="marcador.lng"
      [label]="{
        color: 'white',
        fontSize: '17px',
        fontWeight: 'bold',
        text: marcador.index < 10 ? '0' + marcador.index : marcador.index + ''
      }"
    >
    </agm-marker>
  </div>

  <div *ngIf="this.paradasTrazo && this.paradasTrazo.length > 0 && this.origenTrazo && this.destinoTrazo" >
    <agm-direction
      [visible]="true"
      [origin]="this.origenTrazo"
      [destination]="this.destinoTrazo"
      [waypoints]="this.paradasTrazo"
      [optimizeWaypoints]="false"
      [renderOptions]="{suppressMarkers: true}"
      (onChange)="onChange($event)"
      >
    </agm-direction>
  </div>

  <div *ngIf="this.paradasTrazo && this.paradasTrazo.length === 0  && this.origenTrazo && this.destinoTrazo " >
    <agm-direction
      [origin]="this.origenTrazo"
      [destination]="this.destinoTrazo"
      [renderOptions]="{suppressMarkers: true}"
      (onChange)="onChange($event)"
      >
    </agm-direction>
  </div>
</agm-map>
