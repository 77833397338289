<app-notification (emitNotification)="setNotification($event)"></app-notification>
<mat-drawer-container class="example-container" [hasBackdrop]="true">
  <mat-drawer #drawer [mode]="'push'">
        <app-menu-flota-completa
            *ngIf="drawer"
            [clients]="clients"
            [reloadPoints]="reloadPoints"
            (setFocus)="setFocus($event)"
            (emitMapOptions)="setMapOptions($event)"
            (emitZones)="setZones($event)"
            (emitPoints)="setPoints($event)"
            (emitClients)="setSelectedClients($event)"
            (emitVehicles)="setVehiclesFiltered($event)"
            (emitAlerts)="setAlerts($event)">
        </app-menu-flota-completa>
    </mat-drawer>
    <mat-drawer-content>
        <app-posicionador-mapa></app-posicionador-mapa>
        <button (click)="drawer.toggle()" class="btn-arrow">
            <span class="material-icons">
            keyboard_arrow_right
            </span>
        </button>
        <app-simbologia-mapa></app-simbologia-mapa>
        <app-mapa
            [setNotification]="this.vehicleId"
            [setVehicles]="this.filteredVehicles"
            [setMapOptions]="mapOptions"
            [autoUpdate]="this.autoUpdate"
            [flotaCompleta]="this.flotaCompleta"
            [zones]="this.zones"
            [points]="this.points"
            [clients]="this.selectedClients"
            [setPanToVehicle]="this.vehicleFocused"
            [setAlerts]="this.alertByClient"
            (emitNewPoint)="doReloadPoints($event)"
            (emitNewNotification)="openNotification($event)"></app-mapa>
    </mat-drawer-content>
</mat-drawer-container>
